import {
  updateProductCalculatedFieldsQuery,
  reviewsQuery,
  addReview,
  requestVolumePricesQuery,
  salesAgreementQuery,

  //[195185] [Konrad][Phase-3] [FE] P2 - 3.1. Items with a common attribute view and quick ordering from the product details page (5868)
  updateCalculatedProductGroupPageQuery,
  loadCalculatedProductGroupUomQuery,
  updateProductGroupPageQuery,
} from './queries';
import {
  UPDATE_PRODUCT_CALCULATED_FIELDS,
  productCalculatedFieldsLoaded,
  REVIEWS_REQUESTED,
  reviewsReceived,
  REVIEW_SUBMITTED,
  reviewProcessed,
  VOLUME_PRICES_REQUESTED,
  volumePriceReceived,
  SALES_AGREEMENT_REQUESTED,
  receiveSalesAgreement,

  //[195185] [Konrad][Phase-3] [FE] P2 - 3.1. Items with a common attribute view and quick ordering from the product details page (5868)
  PRODUCTGROUP_BASE_CALCULATED_REQUESTED,
  updateProductGroupCalculatedBase,
  PRODUCTGROUP_PAGE_REQUESTED,
  appendProductGroupPage,
  PRODUCTGROUP_PAGE_CALCULATED_REQUESTED,
  requestProductGroupCalculatedPage,
  appendProductGroupCalculatedPage,
  PRODUCTGROUP_PAGE_FILTER_REQUESTED,
  updateProductGroupPageFiltered,
  PRODUCTGROUP_UOM_CALCULATED_REQUESTED,
  updateProductGroupCalculatedUom,
} from './actions';
import { switchMap, map, takeUntil, exhaustMap, pluck, filter, mergeMap, catchError } from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { rewriteTo } from 'behavior/routing';
import { LOCATION_CHANGED } from 'behavior/events';
import { routesBuilder } from 'routes';
import { retryWithToast, catchApiErrorWithToast } from 'behavior/errorHandling';
import { EMPTY, merge, of } from 'rxjs';
import { resetCaptcha } from 'behavior/captcha';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { requestAbility } from 'behavior/user/epic';
import { AbilityState, AbilityTo } from 'behavior/user/constants';

const productEpic = (action$, state$, dependencies) => {
  const { api, logger } = dependencies;

  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  const onFieldsRequested$ = action$.pipe(
    ofType(UPDATE_PRODUCT_CALCULATED_FIELDS),
    switchMap(action => api.graphApi(updateProductCalculatedFieldsQuery, action.payload).pipe(
      map(mapResponseToAction),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  const onReviewsRequested$ = action$.pipe(
    ofType(REVIEWS_REQUESTED),
    exhaustMap(action => api.graphApi(reviewsQuery, action.payload).pipe(
      pluck('catalog', 'products', 'products', '0', 'reviews'),
      filter(r => r && r.list && r.list.length),
      map(r => reviewsReceived(r.list)),
      takeUntil(locationChanged$),
    )),
  );

  const resetCaptchaAction = resetCaptcha();
  const reviewProcessedAction = reviewProcessed(true);
  const onReviewSubmitted$ = action$.pipe(
    ofType(REVIEW_SUBMITTED),
    exhaustMap(action => api.graphApi(addReview, { data: action.payload }).pipe(
      mergeMap(_ => [reviewProcessedAction, resetCaptchaAction, unlockForm(FormLockKeys.Review)]),
      catchApiErrorWithToast(['INVALID_INPUT'], of(resetCaptchaAction, unlockForm(FormLockKeys.Review))),
      retryWithToast(action$, logger, _ => of(unlockForm(FormLockKeys.Review))),
      takeUntil(locationChanged$),
    )),
  );

  //calculated product group base
  //[195185] [Konrad][Phase-3] [FE] P2 - 3.1. Items with a common attribute view and quick ordering from the product details page (5868)
  const calculatedProductGroupBaseLoad$ = action$.pipe(
    ofType(PRODUCTGROUP_BASE_CALCULATED_REQUESTED),
    mergeMap(({ payload: { options, pageIndex, filter } }) =>
      api.graphApi(updateCalculatedProductGroupPageQuery, { options, pageIndex, filter }).pipe(
        pluck('catalog', 'products', 'products', '0'),
        map(data => updateProductGroupCalculatedBase(data)),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      )),
  );

  //product group page
  //[195185] [Konrad][Phase-3] [FE] P2 - 3.1. Items with a common attribute view and quick ordering from the product details page (5868)
  const productGroupPageLoad$ = action$.pipe(
    ofType(PRODUCTGROUP_PAGE_REQUESTED),
    mergeMap(({ payload: { options, pageIndex, filter } }) =>
      api.graphApi(updateProductGroupPageQuery, { options, pageIndex, filter }).pipe(
        pluck('catalog', 'products', 'products', '0'),
        mergeMap(data =>
          of(
            appendProductGroupPage(data),
            requestProductGroupCalculatedPage(options, pageIndex, filter),
          )),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      )),
  );

  //calculated product group page
  //[195185] [Konrad][Phase-3] [FE] P2 - 3.1. Items with a common attribute view and quick ordering from the product details page (5868)
  const calculatedProductGroupPageLoad$ = action$.pipe(
    ofType(PRODUCTGROUP_PAGE_CALCULATED_REQUESTED),
    mergeMap(({ payload: { options, pageIndex, filter } }) =>
      api.graphApi(updateCalculatedProductGroupPageQuery, { options, pageIndex, filter }).pipe(
        pluck('catalog', 'products', 'products', '0'),
        map(data => appendProductGroupCalculatedPage(data)),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      )),
  );

  //product group page - filter
  //[195185] [Konrad][Phase-3] [FE] P2 - 3.1. Items with a common attribute view and quick ordering from the product details page (5868)
  const productGroupPageFilteredLoad$ = action$.pipe(
    ofType(PRODUCTGROUP_PAGE_FILTER_REQUESTED),
    mergeMap(({ payload: { options, pageIndex, filter } }) =>
      api.graphApi(updateProductGroupPageQuery, { options, pageIndex, filter }).pipe(
        pluck('catalog', 'products', 'products', '0'),
        mergeMap(data =>
          of(
            updateProductGroupPageFiltered(data),
            requestProductGroupCalculatedPage(options, pageIndex, filter),
          )),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      )),
  );

  //calculated UOM change
  //[195185] [Konrad][Phase-3] [FE] P2 - 3.1. Items with a common attribute view and quick ordering from the product details page (5868)
  const calculatedProductGroupUomLoad$ = action$.pipe(
    ofType(PRODUCTGROUP_UOM_CALCULATED_REQUESTED),
    mergeMap(({ payload: { options } }) =>
      api.graphApi(loadCalculatedProductGroupUomQuery, { options }).pipe(
        pluck('catalog', 'products', 'products', '0'),
        map(data => updateProductGroupCalculatedUom(data)),
        retryWithToast(action$, logger),
        takeUntil(locationChanged$),
      )),
  );

  const onVolumePricesRequested$ = action$.pipe(
    ofType(VOLUME_PRICES_REQUESTED),
    switchMap(action => api.graphApi(requestVolumePricesQuery, action.payload).pipe(
      map(data => {
        const volumePrices = data.catalog.volumePrices;
        const { variantId, uomId } = action.payload;

        return volumePriceReceived({ prices: volumePrices, variantId, uomId });
      }),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );

  const onAgreementTermsRequested$ = action$.pipe(
    ofType(SALES_AGREEMENT_REQUESTED),
    pluck('payload'),
    switchMap(({ agreementId, productId }) => api.graphApi(salesAgreementQuery, { agreementId, productIds: [productId] }).pipe(
      pluck('salesAgreements'),
      switchMap(({ agreement, linesAvailability }) => requestAbility(AbilityTo.ViewUnitOfMeasure, state$, dependencies).pipe(
        map(canViewUomAbility => {
          const { uom, uoms } = state$.value.page.product;
          return receiveSalesAgreement(
            productId,
            agreement,
            linesAvailability,
            canViewUomAbility === AbilityState.Available,
            state$.value.settings.product.allowUOMSelection,
            uom,
            uoms,
          );
        }),
      )),
      catchError(_ => {
        logger.warn('Could not retrieve sales agreement terms for the product. '
          + 'The agreement is specified in the basket but the server returned no agreement terms. The server might be in offline mode.');
        return EMPTY;
      }),
      takeUntil(locationChanged$),
    )),
  );

  return merge(
    onFieldsRequested$,
    onReviewsRequested$,
    onReviewSubmitted$,
    onVolumePricesRequested$,
    onAgreementTermsRequested$,

    //[195185] [Konrad][Phase-3] [FE] P2 - 3.1. Items with a common attribute view and quick ordering from the product details page (5868)
    calculatedProductGroupBaseLoad$,
    productGroupPageLoad$,
    calculatedProductGroupPageLoad$,
    productGroupPageFilteredLoad$,
    calculatedProductGroupUomLoad$,
  );
};

export default productEpic;

function mapResponseToAction(data) {
  const product = data.catalog.products.products[0];
  if (!product)
    return rewriteTo(routesBuilder.forNotFound());

  return productCalculatedFieldsLoaded(product);
}