// 217511_Phase 4_3.1_Optimize_Category_Navigation
import loadable from '@loadable/component';
import { Container } from 'components/primitives/grid';

const ProductCategory = loadable(() => import(/*webpackChunkName:"user"*/'./ProductCategory'));

const ProductCategoryPage = () => (
        <>
            <Container>
                <ProductCategory />
            </Container>
        </>

    );

export default ProductCategoryPage