import { useLoadEffect } from 'utils/hooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadUserAbilities, abilitiesPropType } from 'behavior/user';
import { getAbilitiesSelector, getExpAbilitiesSelector } from './selectors';

const wrapperPropTypes = {
  addUserAbilityToLoadQueue: PropTypes.func.isRequired,
  abilities: abilitiesPropType.isRequired,
  dispatch: PropTypes.func,
};

export default function withAbilities(Component, abilityKeys) {
  function Wrapper({ loadUserAbilities, abilities, expired, ...properties }) {
    useLoadEffect(() => {
      const abilitiesToLoad = [];
      for (let length = abilityKeys.length, i = 0; i < length; i++) {
        if (abilities[i] == null || expired[i])
          abilitiesToLoad.push(abilityKeys[i]);
      }

      if (abilitiesToLoad.length)
        loadUserAbilities(abilitiesToLoad);
    }, expired);

    return <Component abilities={abilities} {...properties} />;
  }

  Wrapper.prototype.propTypes = wrapperPropTypes;

  const getAbilities = getAbilitiesSelector(abilityKeys);
  const getExpired = getExpAbilitiesSelector(abilityKeys);

  return connect(state => ({
    abilities: getAbilities(state),
    expired: getExpired(state),
  }), { loadUserAbilities })(Wrapper);
}
