//217511_Phase 4_3.1_Optimize_Category_Navigation
import { useMemo } from 'react';
import { connect } from 'react-redux';
import Breadcrumb from 'components/primitives/breadcrumb';
import { buildCategoryUrl } from '../hooks.js';
import { BackTo } from 'components/primitives/links';
import { Media } from 'components/responsive';
import { useRoutes } from 'components/primitives/route';
import { routesBuilder } from 'routes/routesBuilder';

const CategoryPageBreadcrump = ({ categoryDataList, listpageRouting, backLinkComponent, listPageLink }) => {
  const [categoryUrl] = useRoutes([routesBuilder.forProductCategory()]);

 //240934: Breadcrumb Navigation doesn't work for every search type
  const pathdata = categoryDataList[0]?.categories;
  const categoryPathDataList = pathdata !== undefined ? pathdata : categoryDataList;

  const linkItems = buildBreadcrumbIdNavItemList
  (
    categoryPathDataList,
    listPageLink,
    categoryUrl,
  );

  const backToLink = useMemo(() => {
    if (!listpageRouting.routeData && !listpageRouting.canonicalUrl)
      return null;

    return <BackTo to={listpageRouting?.routeData} url={listpageRouting?.canonicalUrl} texts={backLinkComponent && backLinkComponent.texts} />;
  }, [listpageRouting, backLinkComponent]);

  return (
    <>
      <Media greaterThanOrEqual="md">
        <Breadcrumb items={linkItems} noTopMargin backLink={backToLink} />
      </Media>
    </>
  )
}

export default connect(mapStateToProps)(CategoryPageBreadcrump);

function mapStateToProps(state) {
  const { page: { product } } = state;
  return { categoryDataList: product.categoriesPaths, listpageRouting: product.listpageRoute, listPageLink: product.listPageLink };
}

function buildBreadcrumbIdNavItemList(categoryDataList, listPageLink, categoryUrl) {
  const breadCrumpNavItemList = [];

  for (let i = 0; i < categoryDataList?.length; i++) {
    const item = categoryDataList[i];

    const categoryData = {
      id: item?.id,
      title: item?.name,
      link: { url: '', to: '' },
    };

    categoryData.link.url = i !== categoryDataList?.length - 1
      ? buildCategoryUrl(categoryUrl, item.id)
      : listPageLink?.url;

    breadCrumpNavItemList.push(categoryData);
  }

  return breadCrumpNavItemList;
}