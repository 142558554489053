//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import {
  mergeMap, pluck, startWith, exhaustMap
} from 'rxjs/operators';
import { saveCallOffOrderStatus, SAVE_CALL_OFF_ORDER } from '../actions';
import { saveCallOffOrderMutation } from '../queries';
import { catchApiErrorWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { onDeliveryDateSelected, saveCallOffBtnDiabled, updateCallOffOrderAddressRequested } from '../../../../behavior/user';
import { reloadLocation } from 'behavior/routing';

const setLoading = setLoadingIndicator();
const unsetLoading = unsetLoadingIndicator();

export default function (_action$, state$, { api }) {
  
  return _action$.pipe(
    ofType(SAVE_CALL_OFF_ORDER),
    exhaustMap(({ payload }) => api.graphApi(saveCallOffOrderMutation, { input: payload }).pipe(
      pluck('documents', 'saveCallOffOrder', 'result'),
      mergeMap(result => {
        return of(
          saveCallOffOrderStatus(result),
          reloadLocation(),
          onDeliveryDateSelected(null),
          updateCallOffOrderAddressRequested(undefined),
          unsetLoading,
        );
      }),
      catchApiErrorWithToast(),
      startWith(setLoading),
    )),
  );
}
