import { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showHideListPrice } from 'behavior/app';
import { SimpleText } from 'components/sanaText';
import styles from './AccountToggle.module.scss';
import { AbilityTo } from 'behavior/user/constants';
import { useHasAbilities } from 'components/objects/user';

//[127166] [Konrad] 3.2 Display customer specific prices
const ListPriceToggler = () => {

  const dispatch = useDispatch();
  const initialListPriceState = useSelector(state => state.user.showListPrice);
  const [canSeePrices] = useHasAbilities(AbilityTo.ViewPrices);  

  const { currentListPriceState } = useSelector(({ app }) => ({
    currentListPriceState: app.enableListPrice,
  }));

  const [toggleState, setListPriceToggleState] = useState(currentListPriceState === undefined ? initialListPriceState : currentListPriceState);

  const handleClick = useCallback(event => {
    if (event.ctrlKey)
      return;

    event.preventDefault();
    const toggleState = event.target.checked;
    setListPriceToggleState(toggleState)
    dispatch(showHideListPrice(toggleState));
  }, [toggleState]);


  return (canSeePrices &&
    <>
      <div className={styles.bodyHeader}> </div>
      <div className={styles.toggler}>
        <SimpleText textKey="EnableListPrices" className={styles.stick} />
        <label className={styles.switch}>
          <input type="checkbox" name="enable_list_price_toggler" checked={toggleState} onChange={handleClick} />
          <span className={styles.slider}></span>
        </label>
      </div>
    </>
  );
}

export default ListPriceToggler;