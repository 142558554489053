exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".AccountToggle_switch{position:relative;display:inline-block;width:50px;height:22px;margin-left:10px}.AccountToggle_switch input{opacity:0;width:0;height:0}.AccountToggle_slider{border-radius:30px}.AccountToggle_slider:before{border-radius:25%}input:checked+.AccountToggle_slider{background-color:#2196f3}input:focus+.AccountToggle_slider{box-shadow:0 0 1px #2196f3}input:checked+.AccountToggle_slider:before{-webkit-transform:translateX(20px);transform:translateX(20px)}.AccountToggle_slider{position:absolute;cursor:pointer;top:0;left:0;right:0;bottom:0;background-color:#ccc;transition:.4s}.AccountToggle_slider:before{position:absolute;content:\"\";height:18px;width:18px;left:4px;bottom:2px;background-color:#fff;transition:.4s}.AccountToggle_stick{margin-left:12px}.AccountToggle_toggler{display:block;padding:7px 20px;color:inherit;cursor:pointer;text-decoration:none;transition:background-color .15s}", ""]);

// exports
exports.locals = {
	"switch": "AccountToggle_switch",
	"slider": "AccountToggle_slider",
	"stick": "AccountToggle_stick",
	"toggler": "AccountToggle_toggler"
};