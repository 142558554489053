//127167_Konrad_3.3_Customer_specific_document_download_FrontEnd
import {
  ATTACHMENTS_RECEIVED
} from 'behavior/pages/attachments/actions';
import { createReducer } from 'utils/redux';
import { NAVIGATED } from 'behavior/routing';
import { PageComponentNames } from '../componentNames';

const initialState = {
  items: null
};

export default createReducer(initialState, {
  [ATTACHMENTS_RECEIVED]: onAttachmentItemReceived,
});

function onAttachmentItemReceived(state, action) {
  return {
    ...state,
      items: action.payload,
  };
}
