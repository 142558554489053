import styles from '../AccountMenu.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { MyAccountLink, Link } from 'components/primitives/links';
import { useSelector } from 'react-redux';
import LogoutButton from './LogoutButton';
import { useCurrentRouteAsBackTo } from 'utils/hooks';
import { routesBuilder } from 'routes';
import ListPriceToggler from './ListPriceToggler'; 

//[127166] [Konrad] 3.2 Display customer specific prices
//[127168] [Konrad] 3.4. Customer related settings � price visibility and the document visibility
const SalesAgentMenuItems = ({ logoutDelay }) => {
  const name = useSelector(state => state.user.name);
  const backTo = useCurrentRouteAsBackTo();
  const canSeeEnableListPriceToggleStatus = useSelector(state => state.user.canSeeEnableListPriceToggle);

  return (
    <>
      <div className={styles.bodyHeader}>
        <span className="user-name"><SimpleText textKey="Login_Welcome" /> <b>{name}</b></span>
      </div>
      <Link to={routesBuilder.forRepresent} options={{ backTo }} className={styles.item}>
        <SimpleText textKey="ButtonText_RepresentCustomer" />
      </Link>
      <MyAccountLink className={styles.item} />
      {(canSeeEnableListPriceToggleStatus) && 
        <ListPriceToggler className={styles.item} />        
      }
      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

SalesAgentMenuItems.propTypes = {
  logoutDelay: PropTypes.number,
};

export default SalesAgentMenuItems;
