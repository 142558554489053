export const USER_LOGIN = 'USER_LOGIN';
export const login = (authData, redirectTo) => ({ type: USER_LOGIN, payload: { authData, redirectTo } });

export const LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const loginFailed = () => ({ type: LOGIN_FAILED });

export const LOGIN_FAILED_RESET = 'USER_LOGIN_FAILED_RESET';
export const resetLoginFailed = () => ({ type: LOGIN_FAILED_RESET });

export const USER_REGISTER = 'USER_REGISTER';
export const register = registrationData => ({ type: USER_REGISTER, payload: { registrationData } });

export const USER_CREATE_PROSPECT = 'USER_CREATE_PROSPECT';
export const createProspect = prospectData => ({ type: USER_CREATE_PROSPECT, payload: { prospectData } });

export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const authenticated = user => ({ type: USER_AUTHENTICATED, payload: user });

export const USER_LOGOUT = 'USER_LOGOUT';
export const logout = () => ({ type: USER_LOGOUT });

export const USER_ABILITIES_REQUESTED = 'USER_ABILITIES_REQUESTED';
export const loadUserAbilities = keys => ({ type: USER_ABILITIES_REQUESTED, payload: keys });

export const USER_ABILITIES_LOADED = 'USER_ABILITIES_LOADED';
export const userAbilitiesLoaded = userAbilities => ({ type: USER_ABILITIES_LOADED, payload: userAbilities });

export const REPRESENT_CUSTOMER = 'USER_REPRESENT';
export const representCustomer = (id, shopAccountType) => ({ type: REPRESENT_CUSTOMER, payload: { id, shopAccountType, redirectBack: true } });
export const stopRepresentingCustomer = () => ({ type: REPRESENT_CUSTOMER, payload: { id: null } });

export const IMPERSONATION_FAILED = 'IMPERSONATION_FAILED';
export const impersonationFailed = () => ({ type: IMPERSONATION_FAILED });

export const IMPERSONATION_FAILED_RESET = 'IMPERSONATION_FAILED_RESET';
export const resetImpersonationFailed = () => ({ type: IMPERSONATION_FAILED_RESET });

export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';
export const userProfileUpdated = user => ({ type: USER_PROFILE_UPDATED, payload: user });

export const USER_RELOAD = 'USER_RELOAD';
export const reloadUser = () => ({ type: USER_RELOAD });

export const ADDITIONAL_DELIVERY_DATE_UPDATED = 'ADDITIONAL_DELIVERY_DATE_UPDATED';
export const additionalInfoDeliveryDateSelectedStatus = addditionalDeliveryDateSelected => ({ type: ADDITIONAL_DELIVERY_DATE_UPDATED, payload: { addditionalDeliveryDateSelected } });

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
export const EXPRESS_DELIVERY_SELECTED = 'EXPRESS_DELIVERY_SELECTED';
export const notifyExpressDeliverySelected = expressDeliveryState => ({
  type: EXPRESS_DELIVERY_SELECTED,
  payload: { expressDeliveryState },
});
export const EXPRESS_DELIVERY_STATUS_UPDATED = 'EXPRESS_DELIVERY_STATUS_UPDATED';
export const expressDeliveryStatus = expressDeliverySelected => ({ type: EXPRESS_DELIVERY_STATUS_UPDATED, payload: { expressDeliverySelected } });

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
export const SET_CUTOFF_WARNING_VISIBILITY_STATUS = 'SET_CUTOFF_WARNING_VISIBILITY_STATUS';
export const chengeCutoffWarningVisibility = payload => ({
  type: SET_CUTOFF_WARNING_VISIBILITY_STATUS,
  payload: payload,
});

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
export const EXPRESS_DELIVERY__AND_DELIVERY_DATE_SELECTED = 'EXPRESS_DELIVERY__AND_DELIVERY_DATE_SELECTED';
export const notifyExpressDeliveryAndDeliveryDateSelected = (exDeliveryStatus, selectedDeliveryDate) => ({
  type: EXPRESS_DELIVERY__AND_DELIVERY_DATE_SELECTED,
  payload: { selectedDeliveryDate, exDeliveryStatus },
});

export const EXPRESS_DELIVERY__AND_DELIVERY_DATE_UPDATED = 'EXPRESS_DELIVERY__AND_DELIVERY_DATE_UPDATED';
export const expressDeliveryAndDeliveryDateStatus = status => ({ type: EXPRESS_DELIVERY__AND_DELIVERY_DATE_UPDATED, payload: { status } });

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
export const SHOW_CUTOFF_TIME_WARNING = 'SHOW_CUTOFF_TIME_WARNING';
export const showCutoffTimeWarning = cutoffWarningVisible => ({
  type: SHOW_CUTOFF_TIME_WARNING,
  payload: { cutoffWarningVisible },
});

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
export const SET_CHECKOUT_PAGE_LOADING = 'SET_CHECKOUT_PAGE_LOADING';
export const notifyCheckoutPageLoading = status => ({
  type: SET_CHECKOUT_PAGE_LOADING,
  payload: { status },
});
export const CHECKOU_PAGE_LOADING_COMPLETED = 'CHECKOU_PAGE_LOADING_COMPLETED';
export const checkoutPageLoadedStatus = status => ({ type: CHECKOU_PAGE_LOADING_COMPLETED, payload: { status } });


//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
export const UPDATE_CALLOFF_ORDER_ADDRESS = 'UPDATE_CALLOFF_ORDER_ADDRESS';
export const updateCallOffOrderAddressRequested = payload => ({
  type: UPDATE_CALLOFF_ORDER_ADDRESS,
  payload: { payload },
});

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
export const UPDATE_CALLOFF_ORDER_TYPE = 'UPDATE_CALLOFF_ORDER_TYPE';
export const updateCallOffOrderTypeRequested = payload => ({
  type: UPDATE_CALLOFF_ORDER_TYPE,
  payload: { payload },
});

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
export const UPDATE_CALLOFF_DELIVERY_DATE = 'UPDATE_CALLOFF_DELIVERY_DATE';
export const updateCallOffOrderDeliveryDateRequested = payload => ({
  type: UPDATE_CALLOFF_DELIVERY_DATE,
  payload: { payload },
});

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
export const UPDATE_CALLOFF_ADDRESS_ID = 'UPDATE_CALLOFF_ADDRESS_ID';
export const callOffOrderAddressSelected = payload => ({
  type: UPDATE_CALLOFF_ADDRESS_ID,
  payload: { payload },
});

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
export const UPDATE_CUSTOM_SHIPPING_ADDRESS = 'UPDATE_CUSTOM_SHIPPING_ADDRESS';
export const onCustomcallOffOrderAddressSelected = payload => ({
  type: UPDATE_CUSTOM_SHIPPING_ADDRESS,
  payload: { payload },
});

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
export const UPDATE_DELIVERY_DATE = 'UPDATE_DELIVERY_DATE';
export const onDeliveryDateSelected = payload => ({
  type: UPDATE_DELIVERY_DATE,
  payload: { payload },
});

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
export const SET_CALL_OFF_SAVE_BTN_DISABLED = 'SET_CALL_OFF_SAVE_BTN_DISABLED';
export const saveCallOffBtnDiabled = payload => ({
  type: SET_CALL_OFF_SAVE_BTN_DISABLED,
  payload: { payload },
});

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
export const SAVE_CALL_OFF_ORDER_CLICKED = 'SAVE_CALL_OFF_ORDER_CLICKED';
export const saveCallOffOrderBtnStatus = status => ({ type: SAVE_CALL_OFF_ORDER_CLICKED, payload: { status } });
