import { default as createHandler } from './handler';
//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
import {
    ordersPageQuery,
    quotesPageQuery,
    invoicesPageQuery,
    returnOrdersPageQuery,
    creditNotesPageQuery,
    returnReceiptsPageQuery,
    shipmentsPageQuery,
    callOffOrdersPageQuery,
} from './queries';
import { PageComponentNames } from '../componentNames';
import { DocumentType } from 'behavior/documents';

export { requestDocuments } from './actions';

export { normalizeFilter, createOptions } from './handler';
export const ordersHandler = createHandler(DocumentType.Order, PageComponentNames.Orders, ordersPageQuery);
export const quotesHandler = createHandler(DocumentType.Quote, PageComponentNames.Quotes, quotesPageQuery);
export const invoicesHandler = createHandler(DocumentType.Invoice, PageComponentNames.Invoices, invoicesPageQuery);
export const returnOrdersHandler = createHandler(DocumentType.ReturnOrder, PageComponentNames.ReturnOrders, returnOrdersPageQuery);
export const creditNotesHandler = createHandler(DocumentType.CreditNote, PageComponentNames.CreditNotes, creditNotesPageQuery);
export const returnReceiptsHandler = createHandler(DocumentType.ReturnReceipt, PageComponentNames.ReturnReceipts, returnReceiptsPageQuery);
export const shipmentsHandler = createHandler(DocumentType.Shipment, PageComponentNames.Shipments, shipmentsPageQuery);
export const callOffOrdersHandler = createHandler(DocumentType.CallOffOrder, PageComponentNames.CallOffOrders, callOffOrdersPageQuery); //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders