//217511_Phase 4_3.1_Optimize_Category_Navigation
import { productCategoryQuery } from './queries';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent } from '../system';
import { productCategoryReceived } from './actions';
import { forEach } from 'lodash';

export default ({ params }, _state$, { api }) => {
  const { categoryId, language } = params
  console.log(params);
  return api.graphApi(productCategoryQuery, { categoryId, language }).pipe(
    map(({ pages: { productCategory: page }, productCategoryNavigation }) => {
      if (!page)
        return null;

      page.component = PageComponentNames.ProductCategory;

      return { page, action$: of(productCategoryReceived({ productCategoryNavigation })) };
    }),
    initSystemPageContent(),
  );
};