import { merge, of, concat, throwError, EMPTY, timer } from "rxjs";
import {
  delay,
  first,
  concatMap,
  mergeMap,
  catchError,
  takeUntil,
  pluck,
  filter,
  switchMapTo,
  switchMap,
  map,
  ignoreElements,
  mergeMapTo,
  startWith,
} from "rxjs/operators";
import { concatToIfEmpty } from "utils/rxjs";
import {
  BASKET_ADD_PRODUCTS,
  BASKET_RECEIVED,
  BASKET_PAGE_REQUESTED,
  BASKET_SUMMARY_REQUESTED,
  BASKET_UPDATED,
  BASKET_UPDATE,
  BASKET_CLEAR,
  basketUpdated,
  basketReceived,
  basketSummaryReceived,
  requestBasketSummary,
  BASKET_AGREEMENT_LINES_REQUESTED,
  receiveAgreementLines,
  VOLUME_PRICES_REQUESTED,
  volumePriceReceived,
} from "../actions";
import {
  addProductsMutation,
  clearNonOrderablesMutation,
  basketDetailsQuery,
  basketSummaryQuery,
  getUpdateQuery,
  clearBasketMutation,
  deleteBasketMutation,
  pageSize,
  saveLinesOnlyMutation,
  applySalesAgreementAndAddProductsMutation,
  salesAgreementQuery,
  requestVolumePricesQuery,
} from "../queries";
import { RouteName } from "routes";
import {
  retryWithToast,
  catchBasketCalculationError,
} from "behavior/errorHandling";
import { NAVIGATED } from "behavior/routing";
import {
  setLoadingIndicator,
  unsetLoadingIndicator,
} from "behavior/loadingIndicator";
import { SortingModes, Updaters } from "../constants";
import { routesBuilder } from "routes";
import { getCorrectPageIndex, redirectToPage } from "../helpers";
import { ofType } from "redux-observable";
import {
  basketChangeStarted,
  basketChangeCompleted,
  navigateTo,
} from "behavior/events";
import {
  trackAddToBasket,
  trackRemoveFromBasket,
  getModifiedProductsTrackingData,
  getProductsTrackingDataFromLines,
} from "behavior/analytics";
import { skipIfPreviewWithToast } from "behavior/preview";
import { setErrorMode } from "behavior/basket/actions.errorMode";
import { LOCATION_CHANGED } from 'behavior/events';

export default (action$, state$, dependencies) => {
  const { api, logger } = dependencies;

  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED)); //240779_P5_3.6_Display_of_Relay_Prices

  const handleCalculationError = catchBasketCalculationError((error) => {
    if (state$.value.routing.routeData.routeName !== RouteName.BasketPage)
      return throwError(error);

    logger.error(
      "The following error occurred during basket retrieval:",
      error
    );

    return of(setErrorMode(true), unsetLoadingIndicator());
  });

  const basketAdd$ = action$.pipe(
    ofType(BASKET_ADD_PRODUCTS),
    skipIfPreviewWithToast(state$, dependencies),
    concatMap(({ payload: { lines, updatedById, agreementId } }) => {
      const date = Date.now();
      const addProductsQuery = agreementId
        ? applySalesAgreementAndAddProductsMutation
        : addProductsMutation;
      const addProductsVariables = {
        lines,
        addedLinesCount: lines.length,
        requestModifiedLines: isTrackingEnabled(state$),
      };
      if (agreementId) addProductsVariables.agreementId = agreementId;

      return api.graphApi(addProductsQuery, addProductsVariables).pipe(
        pluck("basket", "addProducts", "modifiedLines", "list"),
        mergeMap((addedLines) => {
          const settings = state$.value.settings;
          const routeName = state$.value.routing.routeData.routeName;
          const actions = [
            basketUpdated(updatedById, date),
            basketChangeCompleted(lines.length),
          ];

          const addedProducts = getProductsTrackingDataFromLines(addedLines);
          if (addedProducts && addedProducts.length) {
            actions.push(trackAddToBasket({ products: addedProducts }));
          }

          if (
            settings.basket.redirectOnAdd &&
            routeName !== RouteName.BasketPage
          )
            actions.push(navigateTo(routesBuilder.forBasket()));

          return actions;
        }),
        catchError((e) =>
          concat(
            of(basketUpdated(updatedById, date), basketChangeCompleted(0)),
            throwError(e)
          )
        ),
        retryWithToast(action$, logger),
        concatToIfEmpty(
          of(basketUpdated(updatedById, date), basketChangeCompleted(0))
        ),
        startWith(basketChangeStarted())
      );
    })
  );

  // Currently BASKET_RECEIVED can be dispatched in the same time as NAVIGATED
  // so add some delay before start listening NAVIGATED to ignore the one dispatched right after BASKET_RECEIVED.
  const navigated$ = timer(50).pipe(mergeMapTo(action$), ofType(NAVIGATED));

  const triggerNonOrderableRemoval$ = action$.pipe(
    ofType(BASKET_RECEIVED),
    switchMapTo(
      state$.pipe(
        first(),
        pluck("basket", "model", "nonOrderableLines"),
        filter((lines) => lines && lines.length),
        delay(2000),
        mergeMap((_) =>
          api.graphApi(clearNonOrderablesMutation).pipe(
            ignoreElements(),
            catchError((e) => {
              logger.error(e);
              return EMPTY;
            })
          )
        ),
        takeUntil(navigated$)
      )
    )
  );

  const startWithBasketChange = startWith(
      setLoadingIndicator(),
      basketChangeStarted()
    ),
    hideIndicatorAction = unsetLoadingIndicator(),
    retryAndHideIndicator = retryWithToast(action$, logger, (_) =>
      of(hideIndicatorAction)
    );

  const load$ = action$.pipe(
    ofType(BASKET_PAGE_REQUESTED),
    map((action) => {
      const params = action.payload;
      if ("index" in params) return params;

      const basket = state$.value.basket.model;
      return {
        ...params,
        index: (basket && basket.page && basket.page.index) || 0,
      };
    }),
    map((params) =>
      isTrackingEnabled(state$) ? { ...params, loadCategories: true } : params
    ),
    switchMap((params) => {
      const basketState = state$.value.basket;
      if (
        basketState &&
        basketState.syncBasket &&
        basketState.syncBasket.page &&
        basketState.syncBasket.page.index === params.index
      ) {
        return of(basketReceived(basketState.syncBasket, params.index));
      }

      return api.graphApi(basketDetailsQuery, params).pipe(
        mergeMap(({ basket }) => {
          const index = params.index;
          const correctedPageIndex = getCorrectPageIndex(
            params.index,
            pageSize,
            basket.productLines.totalCount
          );

          const redirect = correctedPageIndex !== index;
          if (redirect)
            return of(
              redirectToPage(
                state$.value.routing.location.pathname,
                correctedPageIndex,
                true
              )
            );

          return of(hideIndicatorAction, basketReceived(basket, index));
        }),
        takeUntil(navigated$),
        retryAndHideIndicator,
        startWith(setLoadingIndicator())
      );
    })
  );

  const clear$ = action$.pipe(
    ofType(BASKET_CLEAR),
    skipIfPreviewWithToast(state$, dependencies),
    switchMap((action) => {
      const date = Date.now();
      return api
        .graphApi(
          action.payload.remove ? deleteBasketMutation : clearBasketMutation,
          { requestModifiedLines: isTrackingEnabled(state$) }
        )
        .pipe(
          mergeMap(({ basket: basketResult }) => {
            const basket = state$.value.basket.model;
            const newBasket = {
              id: basket ? basket.id : "",
              productLines: {},
              totalCount: 0,
              cleared: true,
            };
            if (state$.value.basket.salesAgreementInfo) {
              newBasket.salesAgreementInfo = {
                ...state$.value.basket.salesAgreementInfo,
                isAppliedToLines: false,
              };
            }

            if (action.payload.remove) {
              newBasket.id = "";
            } else {
              newBasket.editDocumentId = basket.editDocumentId;
              newBasket.editDocumentType = basket.editDocumentType;
            }

            const actions = [
              hideIndicatorAction,
              basketUpdated(Updaters.Basket, date),
              basketChangeCompleted(0),
              basketReceived(newBasket, 0),
              setErrorMode(false),
            ];

            if (basketResult.empty && basketResult.empty.modifiedLines) {
              const productsForTracking = getProductsTrackingDataFromLines(
                basketResult.empty.modifiedLines.list
              );
              if (productsForTracking && productsForTracking.length)
                actions.push(
                  trackRemoveFromBasket({ products: productsForTracking })
                );
            }

            return actions;
          }),
          takeUntil(navigated$),
          retryAndHideIndicator,
          startWithBasketChange
        );
    })
  );

  const reset$ = action$.pipe(
    ofType(BASKET_UPDATED, BASKET_UPDATE, BASKET_ADD_PRODUCTS)
  );
  const basketSummary$ = action$.pipe(
    ofType(BASKET_SUMMARY_REQUESTED),
    switchMap(({ payload: { calculated } }) =>
      timer(50).pipe(
        mergeMapTo(
          api
            .graphApi(basketSummaryQuery, {
              sorting: SortingModes.RecentlyModified,
              calculated,
            })
            .pipe(
              map(({ basket }) => {
                if (basket && calculated != null)
                  basket.calculated = calculated;
                return basketSummaryReceived(basket);
              }),
              handleCalculationError,
              catchError((e) => {
                logger.error(e);

                if (calculated) return of(requestBasketSummary(false));

                return throwError(e);
              }),
              retryWithToast(action$, logger)
            )
        ),
        takeUntil(reset$)
      )
    )
  );

  const modifyBasket$ = action$.pipe(
    ofType(BASKET_UPDATE),
    pluck("payload"),
    switchMap(
      ({ modified, code, countSubLines, index, deliveryType, warehouse, writeOnly }) => { //176741_Konrad_Phase2_3.6.Pick_Up
        const stateBasket = state$.value.basket.model;

        if (index == null) index = stateBasket.page.index;

        if (writeOnly) return saveLinesOnly(modified, api, state$);

        const variables = { countSubLines, index };
        if (modified && modified.length) {
          variables.data = { modified };
          variables.requestModifiedLines = isTrackingEnabled(state$);
        }
        if (code != null) variables.code = code;
        if (isTrackingEnabled(state$)) variables.loadCategories = true;
        
        //176741_Konrad_Phase2_3.6.Pick_Up
        if (deliveryType != null) {
          variables.deliveryType = deliveryType;
        }
        
        //176741_Konrad_Phase2_3.6.Pick_Up
        if (warehouse != null) {
          variables.warehouse = warehouse;
        }

        let query = getUpdateQuery(variables);
        const isUpdate = query != null;
        if (!isUpdate) query = basketDetailsQuery;

        const mapBasketActions = mergeMap((result) => {
          const basket = getBasketFromUpdateResult(result);
          const modifiedLines =
            result.update &&
            result.update.modifiedLines &&
            result.update.modifiedLines.list;
          const actions = [
            basketUpdated(Updaters.Basket, +new Date(basket.modifiedDate)),
          ];
          const correctedPageIndex = getCorrectPageIndex(
            index,
            pageSize,
            basket.productLines.totalCount
          );
          const redirect = correctedPageIndex !== index;

          const { addedProducts, removedProducts } =
            getModifiedProductsTrackingData(
              state$.value.basket.model.productLines.list,
              modifiedLines
            );

          if (addedProducts && addedProducts.length) {
            actions.push(trackAddToBasket({ products: addedProducts }));
          }

          if (removedProducts && removedProducts.length) {
            actions.push(trackRemoveFromBasket({ products: removedProducts }));
          }

          actions.push(basketChangeCompleted(modified.length));

          if (redirect) {
            actions.push(
              redirectToPage(
                state$.value.routing.location.pathname,
                correctedPageIndex,
                true
              )
            );
          } else {
            actions.unshift(hideIndicatorAction);
            actions.push(basketReceived(basket, index));
          }

          return actions;
        });

        return api
          .graphApi(query, variables)
          .pipe(
            pluck("basket"),
            mapBasketActions,
            takeUntil(navigated$),
            handleCalculationError,
            retryAndHideIndicator,
            startWithBasketChange
          );
      }
    )
  );

  //240779_P5_3.6_Display_of_Relay_Prices
  const onVolumePricesRequested$ = action$.pipe(
    ofType(VOLUME_PRICES_REQUESTED),
    switchMap(action => api.graphApi(requestVolumePricesQuery, action.payload).pipe(
      map(data => {
        const volumePrices = data.catalog.volumePrices;
        const { variantId, uomId, productId } = action.payload;

        return volumePriceReceived({ prices: volumePrices, productId, variantId, uomId });
      }),
    )),
  );

  const salesAgreementLinesRequest$ = action$.pipe(
    ofType(BASKET_AGREEMENT_LINES_REQUESTED),
    pluck("payload"),
    switchMap(({ agreementId, productId, basketLineId }) =>
      api
        .graphApi(salesAgreementQuery, { agreementId, productIds: [productId] })
        .pipe(
          pluck("salesAgreements", "agreement", "lines"),
          mergeMap((lines) => [
            receiveAgreementLines(lines, basketLineId),
            unsetLoadingIndicator(),
          ]),
          startWith(setLoadingIndicator())
        )
    )
  );

  return merge(
    basketAdd$,
    triggerNonOrderableRemoval$,
    load$,
    basketSummary$,
    modifyBasket$,
    clear$,
    salesAgreementLinesRequest$,
    onVolumePricesRequested$ //240779_P5_3.6_Display_of_Relay_Prices
  );
};

function getBasketFromUpdateResult(result) {
  if (result.update && result.update.basket) return result.update.basket;

  if (result.addCoupon && result.addCoupon.basket)
    return result.addCoupon.basket;

  //176741_Konrad_Phase2_3.6.Pick_Up
  if (result.selectWarehouse)
    return result.selectWarehouse.basket;

  //176741_Konrad_Phase2_3.6.Pick_Up
  if (result.selectDeliveryType)
    return result.selectDeliveryType.basket;

  return result;
}

function saveLinesOnly(modified, api, state$) {
  const variables = {
      data: { modified },
      requestModifiedLines: isTrackingEnabled(state$),
    },
    date = Date.now();

  return api.graphApi(saveLinesOnlyMutation, variables).pipe(
    pluck("basket", "update", "modifiedLines", "list"),
    mergeMap((modifiedLines) => {
      const actions = [
        basketUpdated(Updaters.Basket, date),
        basketChangeCompleted(modified.length),
      ];
      const { addedProducts, removedProducts } =
        getModifiedProductsTrackingData(
          state$.value.basket.model.productLines.list,
          modifiedLines
        );

      if (addedProducts && addedProducts.length) {
        actions.push(trackAddToBasket({ products: addedProducts }));
      }

      if (removedProducts && removedProducts.length) {
        actions.push(trackRemoveFromBasket({ products: removedProducts }));
      }

      return actions;
    }),
    catchError((_) =>
      of(basketUpdated(Updaters.Basket, date), basketChangeCompleted(0))
    ),
    concatToIfEmpty(
      of(basketUpdated(Updaters.Basket, date), basketChangeCompleted(0))
    )
  );
}

function isTrackingEnabled(state$) {
  return state$.value.analytics && state$.value.analytics.isTrackingEnabled;
}
