//217511_Phase 4_3.1_Optimize_Category_Navigation
import {
  PRODUCTCATEGORY_RECEIVED
} from './actions';
import { createReducer } from 'utils/redux';
import { NAVIGATED } from 'behavior/routing';
import { PageComponentNames } from '../componentNames';

const initialState = {
  productCategoryItems: null
};

export default createReducer(initialState, {
  [PRODUCTCATEGORY_RECEIVED]: onProductCategiryItemReceived,
});

function onProductCategiryItemReceived(state, action) {
  return {
    ...state,
    productCategoryItems: action.payload,
  };
}