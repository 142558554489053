import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

const Autosuggestion = props => {
  const {
    suggestions,
    onFetchRequested,
    onClearRequested,
    onSelected,
    onHighlighted,
    getItemValue,
    renderItem,
    renderItemsContainer,
    focusInputOnItemClick,
    inputProps,
    ...otherProps
  } = props;
  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onFetchRequested}
      onSuggestionsClearRequested={onClearRequested}
      onSuggestionSelected={onSelected}
      onSuggestionHighlighted={onHighlighted}
      getSuggestionValue={getItemValue}
      renderSuggestion={renderItem}
      renderSuggestionsContainer={renderItemsContainer}
      focusInputOnSuggestionClick={focusInputOnItemClick || false}
      inputProps={inputProps}
      {...otherProps}
    />
  );
};

Autosuggestion.propTypes = {
  suggestions: PropTypes.array.isRequired,
  onFetchRequested: PropTypes.func.isRequired,
  onClearRequested: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  onHighlighted: PropTypes.func,
  getItemValue: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  renderItemsContainer: PropTypes.func,
  inputProps: PropTypes.object.isRequired,
  focusInputOnItemClick: PropTypes.bool,
};

export default Autosuggestion;
