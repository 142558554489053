//127167_Konrad_3.3_Customer_specific_document_download_FrontEnd
export const ATTACHMENTS_RECEIVED = 'ATTACHMENTS_RECEIVED';
export const attachmentsReceived = ({ attachments }) => ({
  type: ATTACHMENTS_RECEIVED,
  payload: attachments,
});

export const ATTACHMENTS_REQUESTED = 'ATTACHMENTS_REQUESTED';
export const requestAttachments = (index = 0, size = 10) => ({
  type: ATTACHMENTS_REQUESTED,
  payload: {index, size}
});

export const ATTACHMENT_DOWNLOAD_REQUESTED = 'ATTACHMENT_DOWNLOAD_REQUESTED';
export const downloadAttachment = (attachmentName) => ({
  type: ATTACHMENT_DOWNLOAD_REQUESTED,
  payload: { attachmentName },
});
