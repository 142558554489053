import {
  CHECKOUT_INFO_UPDATED,
  ADDRESS_SELECTED,
  ADDRESS_SAVED,
  EXTRA_PAYMENT_STEP_CHANGED,
  ADDITIONAL_CUSTOMER_DATA_STEP_CHANGED,
  PICKUP_LOCATIONS_RECEIVED,
  ADDITIONAL_DELIVERY_DATE_SELECTED,
} from './actions';
import { EXPRESS_DELIVERY_STATUS_UPDATED } from 'behavior/user/actions';
import { ShippingAddressOption } from './constants';
import { createReducer } from 'utils/redux';
import { APP_HYDRATED } from 'behavior/app';
import { Steps } from './constants';
import { getStepByUrlHash } from './helpers';
import { PageComponentNames } from 'behavior/pages/componentNames';

export default createReducer(null, {
  [ADDRESS_SELECTED]: onAddressSelected,
  [ADDRESS_SAVED]: onAddressSaved,
  [CHECKOUT_INFO_UPDATED]: onInfoUpdated,
  [APP_HYDRATED]: onHydrated,
  [EXTRA_PAYMENT_STEP_CHANGED]: onExtraPaymentStepChanged,
  [ADDITIONAL_CUSTOMER_DATA_STEP_CHANGED]: onCustomerDataStepChanged,
  [PICKUP_LOCATIONS_RECEIVED]: onLocationsReceived,
  [ADDITIONAL_DELIVERY_DATE_SELECTED]: onDateSelected, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  [EXPRESS_DELIVERY_STATUS_UPDATED]: onExpressDeliveryStateUpdated, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
});

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
function onExpressDeliveryStateUpdated(state, action) {
  return {
    ...state,
    info: {
      ...state.info,
      isExpressDelivery: action.payload.expressDeliverySelected,
    },
  };
}


//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
function onDateSelected(state, action) {
  return {
    ...state,
    info: {
      ...state.info,
      selectedDeliveryDate: action.payload.selectedDeliveryDate,
    },
  };
}

function onAddressSelected(state, action) {
  return {
    ...state,
    info: {
      ...state.info,
      ...action.payload.info,
    },
  };
}

function onAddressSaved(state, action) {
  const info = {
    ...state.info,
    ...action.payload.info,
    shippingAddress: {
      ...state.info.shippingAddress,
      address: action.payload.addressInfo,
      shippingOption: ShippingAddressOption.Custom,
    },
  };

  return { ...state, info };
}

function onInfoUpdated(state, action) {
  const info = { ...state.info, ...action.payload };
  if (!action.payload.stepInvalid)
    delete info.stepInvalid;

  return { ...state, info };
}

function onHydrated(state, _action) {
  if (state.component !== PageComponentNames.Checkout || !state.info || state.info.currentStep !== Steps.None)
    return state;

  return {
    ...state,
    info: {
      ...state.info,
      currentStep: getStepByUrlHash(window.location.hash),
    },
  };
}

function onExtraPaymentStepChanged(state, action) {
  const { extraPaymentStep } = action.payload;
  const currentExtraPaymentStep = state.info.extraPaymentStep;

  if (extraPaymentStep && isSamePaymentModule(extraPaymentStep, currentExtraPaymentStep))
    delete extraPaymentStep.model;

  return {
    ...state,
    info: {
      ...state.info,
      extraPaymentStep: extraPaymentStep ? {
        ...currentExtraPaymentStep,
        ...extraPaymentStep,
      } : null,
    },
  };
}

function onLocationsReceived(state, action) {
  const { methodId, locations } = action.payload;
  return {
    ...state,
    info: {
      ...state.info,
      pickupLocations: {
        ...state.info.pickupLocations,
        [methodId]: locations,
      },
    },
  };
}

function isSamePaymentModule(extraPaymentStep1, extraPaymentStep2) {
  return extraPaymentStep1.addonId === extraPaymentStep2.addonId
    && extraPaymentStep1.paymentModuleId === extraPaymentStep2.paymentModuleId;
}

function onCustomerDataStepChanged(state, action) {
  const { customerDataStep } = action.payload;
  const currentCustomerDataStep = state.info.customerDataStep;

  if (customerDataStep.data
    && isSameDataStructure(customerDataStep, currentCustomerDataStep)) {
    delete customerDataStep.data;
  }

  return {
    ...state,
    info: {
      ...state.info,
      customerDataStep: {
        ...currentCustomerDataStep,
        ...customerDataStep,
      },
    },
  };
}

function isSameDataStructure(customerDataStep1, customerDataStep2) {
  const fields1 = customerDataStep1.metadata?.fields;
  const fields2 = customerDataStep2.metadata?.fields;

  if (!fields1 || !fields2)
    return false;

  if (fields1.length !== fields2.length)
    return false;

  for (let i = 0; i < fields1.length; i++)
    if (fields1[i].name !== fields2[i].name
      || fields1[i].type !== fields2[i].type) {
      return false;
    }

  return true;
}
