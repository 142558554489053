import styles from './Summary.module.scss';
import { memo, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { oneOfType, object, number, string, bool } from 'prop-types';
import { joinClasses } from 'utils/helpers';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { useLayoutShifter, useExtras } from 'utils/layout';
import { CartSummaryIcon } from 'components/primitives/icons';

const Header = ({ totalCount, hideLabel, showFixed }) => {
  const extras = useExtras();
  const ref = useRef();
  const { topFixedElementsHeight } = useLayoutShifter();

  useEffect(() => {
    if (!showFixed)
      return;

    // Updates basket link top style property depending on layout top shift value.
    ref.current.style.top = `${topFixedElementsHeight}px`;
  }, [showFixed, topFixedElementsHeight]);

  const shoppingCartIcon = <CartSummaryIcon className={styles.icon} aria-hidden />;

  const basketLink = (
    <Link
      className={
        joinClasses(
          styles.header,
          !totalCount && styles.isEmpty,
          hideLabel && styles.withoutLabel,
          showFixed && styles.fixed,
        )
      }
      to={routesBuilder.forBasket}
      ref={ref}
    >
      {shoppingCartIcon}
      <span className={styles.summaryNumber}>{totalCount}</span>
      {!hideLabel && <span> <SimpleText textKey="ShoppingBasket_QuantityPostfix" /></span>}
    </Link>
  );

  return (
    <>
      {showFixed && extras ? ReactDOM.createPortal(basketLink, extras) : basketLink}
      <span className={`${styles.placeholder} ${showFixed ? styles.fixed : ''}`} aria-hidden>
        {shoppingCartIcon}
        <span className={styles.summaryNumber}>{totalCount}</span>
      </span>
    </>
  );
};

Header.propTypes = {
  totalCount: oneOfType([object, number,  string]),
  hideLabel: bool,
  showFixed: bool,
};

export default memo(Header);
