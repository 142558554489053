import { APP_STATE_UPDATED, SHOW_HIDE_LIST_PRICE_UPDATED } from './actions';
import { createReducer } from 'utils/redux';

export const initialState = {
  offlineMode: false,
  loaded: false,
  enableListPrice: undefined,
};

function reducer(state = initialState, action) {
  if (action.type === APP_STATE_UPDATED)
    return { ...state, error: false, ...action.payload, loaded: true };

  return state;
}

//[127166] [Konrad] 3.2 Display customer specific prices
function onSetListPriceStatusChanged(state, _action) {
  const priceState = _action.payload;
  return {
    ...state,
    enableListPrice: priceState ? priceState : false,
  };
}

export default createReducer(initialState, {
  [SHOW_HIDE_LIST_PRICE_UPDATED]: onSetListPriceStatusChanged,
  [APP_STATE_UPDATED]: reducer,
});