import { ofType } from 'redux-observable';
import { of} from 'rxjs';
import { switchMap, pluck, map, mergeMap, startWith } from 'rxjs/operators';
import { navigateTo } from 'behavior/events';
import { PRODUCTCATEGORY_NAVIGATETOLISTPAGE} from './actions';
import { navigateToListPage } from './queries';

export default (action$, state$, { api, logger }) => action$.pipe(
  ofType(PRODUCTCATEGORY_NAVIGATETOLISTPAGE),
  pluck('payload'),
  switchMap(({ id }) => api.graphApi(navigateToListPage, { id }).pipe(
    pluck('resloveLinkPageUrl','listPageLink'),
    mergeMap(listPageLink => {
      
      if (listPageLink)
        return of(navigateTo(listPageLink.to, listPageLink.url));
    }),
  )),
);