export const SortingModes = Object.freeze({
  DateAdded: 'ADDITION',
  RecentlyModified: 'RECENT',
});

export const Updaters = Object.freeze({
  Basket: 'BASKET',
  Sync: 'SYNC',
  QuickOrder: 'QUICK_ORDER',
});

//176741_Konrad_Phase2_3.6.Pick_Up
export const BasketType = Object.freeze({
  Regular : 'Regular',
  Pickup : 'Pickup',
  Grouped : 'Grouped',
});
