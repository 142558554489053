import documentEpic from './epic.document';
import reorderEpic from './epic.reorder';
import quoteEpic from './epic.quote';
import editEpic from './epic.edit';
import orderAuthorizationEpic from './epic.authorization';
import callOffOrderEpic from './epic.callOffOrder'; //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders

import { combineEpics } from 'redux-observable';

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
export default combineEpics(documentEpic, reorderEpic, quoteEpic, editEpic, orderAuthorizationEpic, callOffOrderEpic);
