export const APP_INIT = 'APP/INIT' as const;
export const initApp = () => ({ type: APP_INIT });

export const APP_INIT_HYDRATE = 'APP/INIT_HYDRATE' as const;
export const initAppHydrate = () => ({ type: APP_INIT_HYDRATE });

export const APP_HYDRATED = 'APP/HYDRATED' as const;
export const notifyHydrated = () => ({ type: APP_HYDRATED });

export const APP_DESTROY = 'APP/DESTROY' as const;
export const destroyApp = () => ({ type: APP_DESTROY });

export const APP_STATE_UPDATE = 'APP/STATE/UPDATE' as const;
export const requestAppStateUpdate = () => ({ type: APP_STATE_UPDATE });

export const APP_STATE_UPDATED = 'APP/STATE/UPDATED' as const;
export const notifyAppStateUpdated = (appState: unknown) => ({ type: APP_STATE_UPDATED, payload: appState });

export const OFFLINE_MODE_CHANGED = 'OFFLINE_MODE/CHANGED' as const;
export const changeOfflineMode = (appState: unknown) => ({ type: OFFLINE_MODE_CHANGED, payload: appState });

export const APP_STORE_CONFIGURED = 'APP/STORE/CONFIGURED' as const;
export const storeConfigured = () => ({ type: APP_STORE_CONFIGURED });

//[127166] [Konrad] 3.2 Display customer specific prices
export const SHOW_HIDE_LIST_PRICE = 'SHOW_HIDE_LIST_PRICE' as const;
export const showHideListPrice = (enableListPrice: boolean) => ({
    type: SHOW_HIDE_LIST_PRICE,
    payload: { enableListPrice },
});

//[127166] [Konrad] 3.2 Display customer specific prices
export const SHOW_HIDE_LIST_PRICE_UPDATED = 'SHOW_HIDE_LIST_PRICE_UPDATED';
export const updatedListPriceStatus = (enableListPrice: boolean) => ({ type: SHOW_HIDE_LIST_PRICE_UPDATED, payload: enableListPrice });

//[127166] [Konrad] 3.2 Display customer specific prices
export type AppAction = ReturnType<
    | typeof initApp
    | typeof initAppHydrate
    | typeof notifyHydrated
    | typeof destroyApp
    | typeof requestAppStateUpdate
    | typeof notifyAppStateUpdated
    | typeof changeOfflineMode
    | typeof storeConfigured
    | typeof showHideListPrice
    | typeof updatedListPriceStatus
>;
