import type { Product, Suggestion } from './types';
import type {
  ProductSelectorAction,
  ReceiveSearchSuggestionsAction,
  ReceiveProductAction,
} from './actions';

import { createReducer } from 'utils/redux';
import {
  PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED,
  PRODUCT_SELECTOR_PRODUCT_RECEIVED,
  PRODUCT_SELECTOR_PRODUCT_CLEARED,
  PRODUCT_SELECTOR_SUGGESTIONS_CLEARED
} from './actions';

type ProductSelectorState = {
  suggestions: Suggestion[];
  selectedProduct: Product | null;
}

export const initialState: ProductSelectorState = {
  suggestions: [],
  selectedProduct: null,
};

export default createReducer<ProductSelectorState, ProductSelectorAction>(initialState, {
  [PRODUCT_SELECTOR_SEARCH_SUGGESTIONS_RECEIVED]: onSearchSuggestionsReceived,
  [PRODUCT_SELECTOR_PRODUCT_RECEIVED]: onProductReceived,
  [PRODUCT_SELECTOR_PRODUCT_CLEARED]: onProductCleared,
  [PRODUCT_SELECTOR_SUGGESTIONS_CLEARED]: onSuggestionProductCleared
});

function onSearchSuggestionsReceived(state: ProductSelectorState, action: ReceiveSearchSuggestionsAction) {
  return { ...state, suggestions: action.payload.suggestions };
}

function onProductReceived(state: ProductSelectorState, action: ReceiveProductAction) {
  return { ...state, selectedProduct: action.payload.product };
}

function onProductCleared(state: ProductSelectorState) {
  return { ...state, selectedProduct: null };
}

function onSuggestionProductCleared(state: ProductSelectorState) {
  return { ...state, suggestions: [] };
}
