/*Ticket 176742: [Konrad][Phase 2]3.7.Call off orders*/
export const enum FormLockKeys {
    Registration = 'registration',
    SubAccount = 'subAccount',
    ChangePassword = 'changePassword',
    ContactUs = 'contactUs',
    Review = 'review',
    ForgotPassword = 'forgotPassword',
    SaveOrderTemplate = 'saveOrderTemplate',
    CreateProspect = 'createProspect',
    EditProfile = 'editProfile',
    CreateDocFreeReturnOrder = 'createDocFreeReturnOrder',
    CreateDocBasedReturnOrder = 'createDocBasedReturnOrder',
    CallOffOrders = 'CallOffOrders',
}
