import { connect, useSelector } from 'react-redux';
import loadable from '@loadable/component';
import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import PageBreadcrumb from 'components/objects/pageBreadcrumb';
import BackLink from './BackLink';
import CategoryPageBreadcrump from './breadcrumb/PageBreadcrump';

const Details = loadable(() => import(/*webpackChunkName:"pdp"*/'./Details'));

const DetailsPage = ({ preset, middleContent, footerContent }) => {
  //217511_Phase 4_3.1_Optimize_Category_Navigation
  var isSearch = useSelector(state => state.routing.location.search);

  return (
    <>
      <Container>
        <CategoryPageBreadcrump backLinkComponent={BackLink} />
      </Container>
      <Details preset={preset} middleContent={middleContent} footerContent={footerContent} />
    </>
  );
}

DetailsPage.propTypes = {
  preset: PropTypes.string.isRequired,
  middleContent: PropTypes.array,
  footerContent: PropTypes.array,
};

export default DetailsPage;
