export const enum UserTypes {
    Anonymous = 'ANONYMOUS',
    Guest = 'GUEST',
    Registered = 'REGISTERED',
    Admin = 'IMPERSONATING_ADMIN',
}

export const enum CustomerTypes {
    B2C = 'B2C',
    B2B = 'B2B',
    Sales = 'SALES_AGENT',
}

export const enum ShopAccountTypes {
    Customer = 'CUSTOMER',
    Contact = 'CONTACT',
    SalesAgent = 'SALES_AGENT',
}

export const enum PriceOption {
    NoPrice = 'NO_PRICE',
    ListPrice = 'LIST_PRICE',
    CustomerPrice = 'CUSTOMER_PRICE',
}

export const enum AbilityTo {
    UseWishlist = 'USE_WISHLIST',
    ViewMyAccountPage = 'VIEW_MY_ACCOUNT_PAGE',
    SubscribeToNewsletter = 'SUBSCRIBE_TO_NEWSLETTER',
    ViewPrices = 'VIEW_PRICES',
    ViewStock = 'VIEW_STOCK',
    ViewUnitOfMeasure = 'VIEW_UNIT_OF_MEASURE',
    OrderProducts = 'ORDER_PRODUCTS',
    CreateOrder = 'SUBMIT_ORDER',
    CreateQuote = 'CREATE_QUOTE',
    Reorder = 'REORDER',
    ViewReturnOrders = 'VIEW_RETURN_ORDERS',
    EditProfile = 'EDIT_PROFILE',
    ViewOrders = 'VIEW_ORDERS',
    ViewQuotes = 'VIEW_QUOTES',
    ViewInvoices = 'VIEW_INVOICES',
    ViewReturnReceipts = 'VIEW_RETURN_RECEIPTS',
    ViewCreditNotes = 'VIEW_CREDIT_NOTES',
    ViewShipments = 'VIEW_SHIPMENTS',
    UseSalesAgreements = 'USE_SALES_AGREEMENTS',
    UseOrderTemplates = 'USE_ORDER_TEMPLATES',
    CreateDocFreeReturnOrder = 'CREATE_DOC_FREE_RETURN_ORDER',
    CreateDocBasedReturnOrder = 'CREATE_DOC_BASED_RETURN_ORDER',
    CreateSubAccount = 'CREATE_SUB_ACCOUNT',
    AuthorizeOrders = 'AUTHORIZE_ORDERS',
    CreateProspect = 'CREATE_PROSPECT',
    ViewCatalog = 'VIEW_CATALOG',
    ViewProductSuggestions = 'VIEW_PRODUCT_SUGGESTIONS',
    Impersonate = 'IMPERSONATE',
    CompareProducts = 'COMPARE_PRODUCTS',
    PromoteQuote = 'PROMOTE_QUOTE',
    PayInvoice = 'PAY_INVOICE',
    UseCustomShippingAddress = 'USE_CUSTOM_SHIPPING_ADDRESS',
    ShipToBillingAddress = 'SHIP_TO_BILLING_ADDRESS',
    CheckoutAsGuest = 'CHECKOUT_AS_GUEST',
    AssignAgreementLineAutomatically = 'ASSIGN_AGREEMENT_LINE_AUTOMATICALLY',
    ViewAttachments = 'VIEW_ATTACHMENTS', //127167_Konrad_3.3_Customer_specific_document_download_Frontend
    ViewCallOffOrders = 'VIEW_ORDERS', //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
    ViewLoyaltyInformation = 'VIEW_LOYALTY_INFORMATION', //Konrad_P5_3_4_Additional_Shop_Account_Settings
}

export const enum AbilityState {
    NotAvailable = 'NOT_AVAILABLE',
    Unauthorized = 'UNAUTHORIZED',
    Available = 'AVAILABLE',
    TemporaryNotAvailable = 'TEMPORARY_NOT_AVAILABLE',
}
