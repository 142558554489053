import { PageComponentNames } from '../componentNames';
import { of } from 'rxjs';
import { map, mergeMap, first } from 'rxjs/operators';
import { DocumentAbilitiesMap, DocumentType, getDocumentField, getDocumentDetailsField } from 'behavior/documents';
import { createAbilityMiddleware } from '../middlewares';
import { CustomerTypes } from 'behavior/user/constants';
import { getDocumentPageQuery } from './queries';
import { printModeEnabled } from 'behavior/printMode';
import { isQuoteInStock } from './helpers';
import { initSystemPageContent } from '../system';
import { loadSystemPageQuery } from '../system/queries';

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
const pageComponentNamesPerDocumentType = {
  [DocumentType.Quote]: PageComponentNames.Quote,
  [DocumentType.Invoice]: PageComponentNames.Invoice,
  [DocumentType.ReturnOrder]: PageComponentNames.ReturnOrder,
  [DocumentType.CreditNote]: PageComponentNames.CreditNote,
  [DocumentType.ReturnReceipt]: PageComponentNames.ReturnReceipt,
  [DocumentType.Shipment]: PageComponentNames.Shipment,
  [DocumentType.CallOffOrder]: PageComponentNames.CallOffOrder,
};

export default (routeData, state$, dependencies) => {
  const { params: { id, documentType, originalOrderId: orderId, previewToken } } = routeData;
  const { api } = dependencies;

  if (!documentType)
    return of(null);

  const isPrintMode = printModeEnabled(state$.value.routing);
  const createPageResult = (page, document) => ({
    page: {
      ...page,
      component: pageComponentNamesPerDocumentType[documentType] || PageComponentNames.Order,
      id,
      documentType,
      isPrintMode,
      promotionResult: state$.value.page.promotionResult,
      document,
    },
  });

  if (previewToken) {
    const pageField = getDocumentDetailsField(documentType);
    return api.graphApi(loadSystemPageQuery(pageField)).pipe(
      map(({ pages: { [pageField]: page } }) => !page ? null : createPageResult(page, {
        id: '',
        documentId: '',
        currency: {},
        shipping: { tracking: {} },
        payment: { isPaidOnAccount: false },
        addresses: {},
        totals: {},
        lines: { totalCount: 0, itemLines: [] },
      })),
      initSystemPageContent(),
    );
  }

  const ability = DocumentAbilitiesMap[documentType];
  const abilityMiddleware = createAbilityMiddleware(ability, false);

  const loadDocumentPage = state => {
    const {
      user: { customerType },
      settings: {
        tax: { mode: taxMode },
        checkout: { maxOverviewLines },
      },
    } = state;

    const query = getDocumentPageQuery(
      documentType,
      customerType !== CustomerTypes.B2C,
      taxMode,
    );
    return api.graphApi(query, {
      id,
      linesLimit: isPrintMode ? null : maxOverviewLines,
      orderId,
    }).pipe(
      mergeMap(({ pages, documents }) => {
        const page = pages[getDocumentDetailsField(documentType)];
        if (!page)
          return of(null);

        const field = getDocumentField(documentType);
        const document = documents[field] && documents[field].byId;
          
        if (documentType === DocumentType.Quote)
          document.hasStock = isQuoteInStock(document);

        return of(createPageResult(page, document));
      }),
      initSystemPageContent(),
    );
  };

  const next = _ => {
    if (state$.value.settings.loaded)
      return loadDocumentPage(state$.value);

    return state$.pipe(
      first(s => s.settings.loaded),
      mergeMap(loadDocumentPage),
    );
  };

  return abilityMiddleware(next, routeData, state$, dependencies);
};
