import { memo } from 'react';
import PropTypes from 'prop-types';
import { useSanaTexts } from 'components/sanaText';
import SearchBox from './SearchBox';
import { RouteName } from 'routes';
import { useRoutes } from 'components/primitives/route';
import { defaultSearchParams } from './constants';
import { useAbilities } from 'components/objects/user';
import { AbilityState, AbilityTo } from 'behavior/user/constants';
import Schema from './Schema';
import { makeSimpleText } from 'utils/render';
import DisabledSearchBox from './DisabledSearchBox';
import { useIsPreview } from '../preview';

const searchRoute = { routeName: RouteName.Search, params: { q: defaultSearchParams } };

const Search = ({ className, onFocus, onBlur, initialModel, id, designMode }) => {
  const ability = useSearchAbility();
  const isPreview = useIsPreview();
  const [routePath] = useRoutes([searchRoute]);

  {/*TICKET 176745 - 3.9. Barcode scanning */}
  const [placeholder, searchTitle, barcodeScanningTitle] = useSanaTexts([!initialModel?.placeholder && 'Search_SearchPrefix', 'ButtonAltText_SearchProducts', 'ButtonAltText_BarcodeScanning']).texts;

  const disabled = designMode || (!routePath && isPreview);

  if (ability.isLoading || (!routePath && !disabled))
    return null;

  if (!ability.available)
    return <DisabledSearchBox className={className} />;

  return (
    <div className="search-with-barcode">
      {!disabled && <Schema routePath={routePath} />}
      {/*TICKET 176745 - 3.9. Barcode scanning*/}
      <SearchBox
        id={id}
        placeholder={initialModel?.placeholder || makeSimpleText(placeholder)}
        routePath={routePath}
        searchTitle={searchTitle}
        className={className}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        barcodeScanningTitle={barcodeScanningTitle}
      />
    </div>
  );
};

Search.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  className: PropTypes.string,
  initialModel: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
  id: PropTypes.string.isRequired,
  designMode: PropTypes.bool,
};

export default memo(Search);

const abilityKeys = [AbilityTo.ViewCatalog];

function useSearchAbility() {
  const { isLoading, abilities } = useAbilities(abilityKeys);
  return {
    isLoading,
    available: !isLoading && abilities[0] === AbilityState.Available,
  };
}
