//217511_Phase 4_3.1_Optimize_Category_Navigation
export const PRODUCTCATEGORY_RECEIVED = 'PRODUCTCATEGORY_RECEIVED';
export const productCategoryReceived = ({ productCategoryNavigation }) => ({
  type: PRODUCTCATEGORY_RECEIVED,
  payload: productCategoryNavigation,
});

export const PRODUCTCATEGORY_NAVIGATETOLISTPAGE = 'PRODUCTCATEGORY_NAVIGATETOLISTPAGE';
export const productCategoryNavigateToListPage = (id) => ({
  type: PRODUCTCATEGORY_NAVIGATETOLISTPAGE,
  payload: { id },
});