import styles from './Search.module.scss';
import modalStyles from 'components/objects/modals/Modal.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useOnChange } from 'utils/hooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Autosuggest, highlightStringBySpans } from 'components/primitives/autosuggest';
import { escapeRegexCharacters } from 'utils/helpers';
import { debounce, stubTrue } from 'lodash';
import { useHeaderContext } from 'components/sections/headerContext';
import SuggestionItem from './SuggestionItem';
import { SearchContainerContext } from './SearchContainerContext';
import SuggestionsContainer from './SuggestionsContainer';
import { searchProducts, searchProductsClear } from 'behavior/products/search/suggestions';
import { navigateTo } from 'behavior/events';
import { RouteName } from 'routes';
import { defaultSearchParams } from './constants';
import { trackProductClick, EventSource } from 'behavior/analytics';
import { SearchButtonIcon } from 'components/primitives/icons';
import { Modal, useModal } from 'components/objects/modals';
import { useSimpleTexts, SimpleText } from 'components/sanaText';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import Spinner from 'components/primitives/spinner/Spinner';
import { addProducts } from 'behavior/basket';
import { generateKey } from 'utils/helpers';
import { Button } from 'components/primitives/buttons';
import barcodescannerimg from './icons/icons/barcode32.png'
import { toasts } from 'behavior/toasts';
import { Helmet } from 'react-helmet';

const defaultDependencies = [];

//TICKET 176745 - 3.9. Barcode scanning 
const SearchBox = ({
  placeholder,
  searchTitle,
  searchProducts,
  searchProductsClear,
  suggestions,
  navigateTo,
  trackProductClick,
  routePath,
  noImageUrl,
  viewMode,
  routeData,
  prevRouteData,
  className = '',
  onFocus,
  onBlur,
  id,
  disabled,
  barcodeScanning,
  barcodeScanningTitle,
  addProducts,
  modifiedDate,
  updatedById,
}) => {

  const headerContext = useHeaderContext();
  const formRef = useRef(null);
  const highlightedSuggestionRef = useRef();
  const [maxHeight, setMaxHeight] = useState('');
  const [value, setValue] = useState(headerContext.searchInputValue || '');

  if (headerContext.available)
    headerContext.searchInputValue = value;

  useOnChange(() => {
    if (!headerContext.available) {
      setValue('');
      return;
    }

    const routeName = routeData?.routeName;
    if (routeName === RouteName.Search && routeData.params.q) {
      setValue(routeData.params.q);
      return;
    }

    if (!value)
      return;

    const prevRouteName = prevRouteData?.routeName;

    if (
      prevRouteName
      && prevRouteName === routeName
      && prevRouteData.params.language !== routeData.params.language
    )
      return;

    if (
      prevRouteName === RouteName.Search
      && routeName === RouteName.ProductDetails
    )
      return;

    setValue('');
  }, [routeData, prevRouteData], false);

  useEffect(() => {
    const resizeHandler = debounce(() => {
      if (!formRef.current)
        return;

      setMaxHeight(calculateMaxHeight(formRef.current));
    }, 250);

    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, [formRef.current]);

  const search = useMemo(
    () => debounce(value => !disabled && searchProducts(value), 250),
    [disabled],
  );

  const onFetchRequested = useCallback(({ value, reason }) => {
    setMaxHeight(calculateMaxHeight(formRef.current));
    if (reason === 'input-changed' && value.length > 1)
      search(value);
  }, defaultDependencies);

  const onSelected = useCallback((event, { suggestion }) => {
    event.preventDefault();
    searchProductsClear();
    setValue('');
    document.getElementById('layout').focus();

    trackProductClick({
      product: suggestion,
      source: EventSource.SearchInput,
    });

    navigateTo(suggestion.routeData, suggestion.url);
  }, defaultDependencies);

  const onSubmit = useCallback(event => {
    event.preventDefault();
    if (disabled)
      return;
    searchProductsClear();
    const encodedValue = encodeURIComponent(value);
    const searchURL = routePath.replace(defaultSearchParams, encodedValue);

    navigateTo({ routeName: RouteName.Search, params: { q: value, viewMode } }, searchURL);

    document.getElementById('layout').focus();
  }, [routePath, value, disabled]);

  const onHighlighted = ({ suggestion }) => {
    highlightedSuggestionRef.current = suggestion;
  };

  const renderItem = useCallback(suggestion => <SuggestionItem suggestion={suggestion} />, defaultDependencies);
  const onChange = useCallback((_event, { newValue }) => setValue(newValue), defaultDependencies);

  const onKeyDown = e => {
    if (highlightedSuggestionRef.current && (e.key === 'Tab' || e.which === 9)) {
      onSelected(e, { suggestion: highlightedSuggestionRef.current });
      e.preventDefault();
    }
  };

  const inputProps = {
    id,
    placeholder,
    value,
    onChange,
    onFocus,
    onBlur,
    onKeyDown,
    name: 'q',
    type: 'search',
  };

  const memorizedSuggestions = useMemo(() => getSuggestions(value, suggestions, noImageUrl), [suggestions]);
  const searchPathWithoutParams = useMemo(() => routePath ? routePath.split('?')[0] : '', [routePath]);

  //START - TICKET 176745 - 3.9. Barcode scanning
  const { opened, show, hide } = useModal();
  const { texts, textsLoaded } = useBarcodeScanningTexts();

  const defaultData = 'Not Found';
  const [data, setData] = useState(defaultData);
  const [displayingScanPopup, setDisplayingScanPopup] = useState(false);
  const [stopStream, setStopStream] = useState(false);
  const [itemFound, setItemFound] = useState(undefined);
  const [componentId] = useState(generateKey);

  const openBarcodePopup = useHandlerLockerInPreview(() => {

    setData(defaultData);
    setDisplayingScanPopup(true);

    show();

  });

  const closeBarcodePopup = () => {

    setStopStream(false);
    setItemFound(undefined);
    setDisplayingScanPopup(false);

    setTimeout(() => {

      searchProductsClear();

      const value = data.trim();
      const encodedValue = encodeURIComponent(value);
      const searchURL = routePath.replace(defaultSearchParams, encodedValue);

      navigateTo({ routeName: RouteName.Search, params: { q: value, viewMode } }, searchURL);

      document.getElementById('layout').focus();

      hide();

    }, 100);

  };

  const reloadBarcodePopup = () => {

    setStopStream(false);
    setItemFound(undefined);
    setDisplayingScanPopup(true);

    setTimeout(() => {

      hide();

      setTimeout(() => {

        setData(defaultData);
        setStopStream(false);
        setItemFound(undefined);

        show();

      });

    });

  };

  const readBarcode = (err, result) => {

    if (result) {

      if (isUrl(result.text)) {
        return false;
      }

      setData(result.text);
      setStopStream(true);

      search(result.text);
    }
    else
      setData(defaultData);

  };

  const isUrl = (value) => {

    var pattern = /^((http|https|ftp):\/\/)/;
    return pattern.test(value);
  }

  const onError = (error) => {
    console.log({ barcodeError: error });
  };

  useEffect(() => {

    if (displayingScanPopup) {

      const foundItems = suggestions.filter((item) => {
        return item.id === data.trim();
      });

      if (foundItems.length === 1 || suggestions.length === 1) {

        let productId = data.trim();
        if (suggestions.length === 1)
          productId = suggestions[0].id;

        setItemFound(true);

        const line = {
          productId: productId,
          variantId: null,
          uomId: null,
          quantity: 1,
          salesAgreementLineId: null,
          isBarcodeScanning: true,
        };

        setDisplayingScanPopup(false);
        addProducts([line], componentId);

      }
      else
        setItemFound(false);
    }

  }, [suggestions])

  useEffect(() => {

    if (updatedById === componentId) {

      if (!displayingScanPopup) {
        reloadBarcodePopup();

        setTimeout(() => {
          toasts.success('', { textKey: 'BarcodeScanning_itemAddedToCart' });
        }, 100);
      }

    }

  }, [modifiedDate, updatedById]);
  //END - TICKET 176745 - 3.9. Barcode scanning

  return (
    <div className={`${styles.searchBox} ${className}`}>
      <Helmet>
        <body className="barcode-popup" />
      </Helmet>
      { /*TICKET 176745 - 3.9. Barcode scanning*/
        barcodeScanning && (<Modal highlightedCloseBtn="true" opened={opened} hide={hide} className={`${styles.barcodeScanningPopup} barcode-modle-popup`} resetContentOnClose>
          <h2 className={`h3 barcode-top-title ${modalStyles.title}`}>{texts.header}</h2>
          <div>
            {stopStream && itemFound !== undefined && itemFound === true && (<div className="main-popup">
              <div className="barcode-rec-process">
                <div className={`${styles.loader} barcode-recoganized`}>
                  <Spinner className={styles.indicator} />
                  <div className={`${styles.loaderTitle} barcode-rec-text`}><SimpleText textKey="BarcodeScanning_recognized" /></div>
                </div>
              </div>
            </div>)}

            {stopStream && itemFound !== undefined && itemFound === false && (<div className="main-popup">
              <div className="barcode-error-popup">
                <div className={`${styles.loaderTitle} barcode-error`}>
                  <SimpleText textKey="BarcodeScanning_notRecognized" />
                  <Button
                    title={texts.btnTextOK}
                    onClick={closeBarcodePopup}
                    className={`${btnStyles.btn} ${btnStyles.btnExtraSmall} popup-close-btn`}
                    aria-keyshortcuts="Escape"
                  >
                    {texts.btnTextOK}
                  </Button>
                </div>
              </div>
            </div>)}

            <BarcodeScannerComponent
              stopStream={stopStream}
              onUpdate={readBarcode}
              onError={onError}
            />
          </div>
          <h2 className={`h3 barcode-bottom-title ${modalStyles.title}`}>{texts.footer}</h2>
        </Modal>)
      }

      <form role="search" method="get" action={searchPathWithoutParams} onSubmit={onSubmit} ref={formRef}>
        <SearchContainerContext.Provider value={{ maxHeight }}>
          <Autosuggest
            suggestions={memorizedSuggestions}
            onFetchRequested={onFetchRequested}
            onClearRequested={searchProductsClear}
            onSelected={onSelected}
            onHighlighted={onHighlighted}
            getItemValue={getItemValue}
            renderItem={renderItem}
            renderItemsContainer={SuggestionsContainer}
            inputProps={inputProps}
            theme={styles}
            id={id} // Suffix to be added to 'react-autowhatever-' to resolve ID duplicates conflict.
          />
        </SearchContainerContext.Provider>
        {/*TICKET 176745 - 3.9. Barcode scanning*/}
        {barcodeScanning && (<button onClick={openBarcodePopup} type="button" className={`${styles.barcodeScanning} barcode-btn`} title={barcodeScanningTitle}>
          <img src={barcodescannerimg} />
        </button>)}
        <button type="submit" className={styles.submit} title={searchTitle}>
          <SearchButtonIcon className={styles.searchIcon} aria-hidden />
        </button>
      </form>
    </div>
  );
};

const routeDataPropType = PropTypes.shape({
  routeName: PropTypes.string,
  params: PropTypes.shape({
    q: PropTypes.string,
    language: PropTypes.number,
  }),
});

//TICKET 176745 - 3.9. Barcode scanning 
SearchBox.propTypes = {
  placeholder: PropTypes.string,
  searchTitle: PropTypes.string,
  barcodeScanningTitle: PropTypes.string,
  searchProducts: PropTypes.func.isRequired,
  searchProductsClear: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  routePath: PropTypes.string,
  navigateTo: PropTypes.func.isRequired,
  trackProductClick: PropTypes.func.isRequired,
  noImageUrl: PropTypes.string,
  viewMode: PropTypes.string,
  routeData: routeDataPropType,
  prevRouteData: routeDataPropType,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

//TICKET 176745 - 3.9. Barcode scanning 
const mapStateToProps = ({
  suggestions,
  settings: { product, search },
  routing: { routeData, previous },
  basket: { modifiedDate, updated }
}) => ({
  suggestions: suggestions.products,
  noImageUrl: product ? product.noImage.small : null,
  viewMode: search && search.defaultViewMode,
  barcodeScanning: search.barcodeScanning,
  modifiedDate,
  updatedById: updated.updaterId,
  routeData,
  prevRouteData: previous?.routeData,
});

export default connect(
  mapStateToProps,
  { searchProducts, searchProductsClear, navigateTo, trackProductClick, addProducts },
)(SearchBox);

//Ticket 217512: [Konrad][Phase 4] 3.2 Display Search Results with Product Grouping
function getSuggestions(value, products, noImageUrl) {
  const escapedValue = escapeRegexCharacters(value.trim());
  const regex = new RegExp(`(${escapedValue})`, 'gi');

  return products
    .map(product => product.productGroup ? {
      ...product,
      id: product.productGroup.id,
      imageUrl: product.productGroup.imageUrl ?? noImageUrl,
      highlightedText: highlightStringBySpans(`${product.productGroup.title || ''} - ${product.productGroup.id}`, regex, styles.highlight),
    } : {
      ...product,
      imageUrl: product.imageUrl ? product.imageUrl : noImageUrl,
      highlightedText: highlightStringBySpans(`${product.title} - ${product.id}`, regex, styles.highlight),
    });
}

function getItemValue(suggestion) {
  return suggestion.title;
}

function calculateMaxHeight(form) {
  const innerHeight = window.innerHeight;
  return innerHeight - form.getBoundingClientRect().bottom + 'px';
}

//TICKET 176745 - 3.9. Barcode scanning 
function useBarcodeScanningTexts() {
  const {
    texts: [
      header,
      footer,
      recognized,
      notRecognized,
      btnTextOK,
    ],
    loaded: textsLoaded,
  } = useSimpleTexts([
    'BarcodeScanning_header',
    'BarcodeScanning_footer',
    'BarcodeScanning_recognized',
    'BarcodeScanning_notRecognized',
    'ButtonText_Ok',
  ]);

  return {
    texts: {
      header,
      footer,
      recognized,
      notRecognized,
      btnTextOK,
    },
    textsLoaded,
  };
}
