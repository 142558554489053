import createRenderer from './createProfileRenderer';
import { routesBuilder, RouteName } from 'routes';
import { PageComponentNames } from 'behavior/pages';
import myAccountRenderer from './myAccount';
import changePasswordRenderer from './changePassword';
import editProfileRenderer from './editProfile';
import { subAccountsRenderer, subAccountRenderer } from './subAccounts';
import orderAuthorizationsRenderer from './orderAuthorizations';
import prospectRenderer from './createProspect';
import orderTemplatesRenderer from './orderTemplates';
import { salesAgreementsRenderer, salesAgreementDetailsRenderer } from './salesAgreements';

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
import {
  ordersRenderer,
  quotesRenderer,
  invoicesRenderer,
  returnOrdersRenderer,
  creditNotesRenderer,
  returnReceiptsRenderer,
  shipmentsRenderer,
  callOffOrdersRenderer
} from './documents';
import documentRenderer from './document';
import docFreeReturnOrderRenderer from './createDocFreeReturnOrder';
import docBasedReturnOrderRenderer from './createDocBasedReturnOrder';
import attachmentsRenderer from './attachments';

const profileBackTextKeys = ['ButtonText_BackToProfile', 'AccountDashboard'];

export default {
  [PageComponentNames.MyAccount]: createRenderer('MyAccount_Header', myAccountRenderer, { linkKey: '/' }),
  [PageComponentNames.ChangePassword]: createRenderer('ChangePassword', changePasswordRenderer, {
    linkKey: '/',
    backTo: {
      route: routesBuilder.forMyAccount(),
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.EditProfile]: createRenderer('YourDetails', editProfileRenderer, { linkKey: 'edit' }),
  [PageComponentNames.SubAccounts]: createRenderer('SubAccounts', subAccountsRenderer, { linkKey: 'subAccounts' }),
  [PageComponentNames.SubAccount]: createRenderer('', subAccountRenderer, {
    linkKey: 'subAccounts',
    backTo: {
      route: routesBuilder.forSubAccounts(),
      texts: ['ButtonText_BackToSubAccounts', 'ManageSubAccounts'],
    },
  }),
  [PageComponentNames.CreateProspect]: createRenderer('CreateProspect_Header', prospectRenderer, { linkKey: 'createProspect' }),
  [PageComponentNames.Attachments]: createRenderer('Attachment_Header', attachmentsRenderer, { linkKey: 'attachments' }), //127167_Konrad_3.3_Customer_specific_document_download
  [PageComponentNames.OrderTemplates]: createRenderer('OrderTemplates_Header', orderTemplatesRenderer, { linkKey: 'templates' }),
  [PageComponentNames.SalesAgreements]: createRenderer('SalesAgreements_ListPageHeader', salesAgreementsRenderer, { linkKey: 'agreements' }),
  [PageComponentNames.SalesAgreement]: createRenderer('', salesAgreementDetailsRenderer, {
    linkKey: 'agreements',
    backTo: {
      route: routesBuilder.forSalesAgreements(),
      texts: ['ButtonText_BackToSalesAgreements', 'MySalesAgreements'],
    },
  }),
  [PageComponentNames.Orders]: createRenderer('OrderHistory_OrderHistoryHeader', ordersRenderer, {
    linkKey: 'orders',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),

  //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  [PageComponentNames.CallOffOrders]: createRenderer('CallOffOrderHistory_OrderHistoryHeader', callOffOrdersRenderer, {
    linkKey: 'callOffOrders',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),

  [PageComponentNames.Quotes]: createRenderer('QuoteHistory_Header', quotesRenderer, {
    linkKey: 'quotes',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.Invoices]: createRenderer('InvoicesHistory_Header', invoicesRenderer, {
    linkKey: 'invoices',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.ReturnOrders]: createRenderer('ReturnOrderHistory_Header', returnOrdersRenderer, {
    linkKey: 'returnOrders',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.CreditNotes]: createRenderer('CreditNotesHistory_Header', creditNotesRenderer, {
    linkKey: 'creditNotes',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.ReturnReceipts]: createRenderer('ReturnReceiptsHistory_Header', returnReceiptsRenderer, {
    linkKey: 'returnReceipts',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.Shipments]: createRenderer('ShipmentsHistory_Header', shipmentsRenderer, {
    linkKey: 'shipments',
    backTo: {
      texts: profileBackTextKeys,
    },
  }),
  [PageComponentNames.Order]: createRenderer('', documentRenderer, {
    linkKey: 'orders',
    backTo: {
      route: routesBuilder.forOrders(),
      texts: ['ButtonText_BackToOrders', 'MyOrders'],
      supportedRoutes: new Set([RouteName.MyAccount, RouteName.Orders, RouteName.OrderAuthorizations]),
    },
  }),

  //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  [PageComponentNames.CallOffOrder]: createRenderer('', documentRenderer, {
    linkKey: 'callOffOrders',
    backTo: {
      route: routesBuilder.forCallOffOrders(),
      texts: ['ButtonText_BackToCallOffOrders', 'MyCallOffOrders'],
      supportedRoutes: new Set([RouteName.CallOffOrders]),
    },
  }),

  [PageComponentNames.Quote]: createRenderer('', documentRenderer, {
    linkKey: 'quotes',
    backTo: {
      route: routesBuilder.forQuotes(),
      texts: ['ButtonText_BackToQuotes', 'MyQuotes'],
      supportedRoutes: new Set([RouteName.Quotes]),
    },
  }),
  [PageComponentNames.Invoice]: createRenderer('', documentRenderer, {
    linkKey: 'invoices',
    backTo: {
      route: routesBuilder.forInvoices(),
      texts: ['ButtonText_BackToInvoices', 'MyInvoices'],
      supportedRoutes: new Set([RouteName.Invoices]),
    },
  }),
  [PageComponentNames.ReturnOrder]: createRenderer('', documentRenderer, {
    linkKey: 'returnOrders',
    backTo: {
      route: routesBuilder.forReturnOrders(),
      texts: ['ButtonText_BackToReturnOrders', 'MyReturnOrders'],
      supportedRoutes: new Set([RouteName.ReturnOrders]),
    },
  }),
  [PageComponentNames.CreditNote]: createRenderer('', documentRenderer, {
    linkKey: 'creditNotes',
    backTo: {
      route: routesBuilder.forCreditNotes(),
      texts: ['ButtonText_BackToCreditNotes', 'MyCreditNotes'],
      supportedRoutes: new Set([RouteName.CreditNotes]),
    },
  }),
  [PageComponentNames.ReturnReceipt]: createRenderer('', documentRenderer, {
    linkKey: 'returnReceipts',
    backTo: {
      route: routesBuilder.forReturnReceipts(),
      texts: ['ButtonText_BackToReturnReceipts', 'MyReturnReceipts'],
      supportedRoutes: new Set([RouteName.ReturnReceipts]),
    },
  }),
  [PageComponentNames.Shipment]: createRenderer('', documentRenderer, {
    linkKey: 'shipments',
    backTo: {
      route: routesBuilder.forShipments(),
      texts: ['ButtonText_BackToShipments', 'MyShipments'],
      supportedRoutes: new Set([RouteName.Shipments]),
    },
  }),
  [PageComponentNames.OrderAuthorizations]: createRenderer(
    'AuthorizationHistory',
    orderAuthorizationsRenderer,
    { linkKey: 'authorizations' },
  ),
  [PageComponentNames.CreateDocFreeReturnOrder]: createRenderer('ReturnOrder_Header', docFreeReturnOrderRenderer, {
    linkKey: 'returnOrders',
    backTo: {
      route: routesBuilder.forReturnOrders(),
      texts: ['ButtonText_BackToReturnOrders', 'MyReturnOrders'],
    },
  }),
  [PageComponentNames.CreateDocBasedReturnOrder]: createRenderer('', docBasedReturnOrderRenderer, {
    linkKey: 'returnOrders',
  }),
};
