import {
  USER_AUTHENTICATED,
  USER_ABILITIES_LOADED,
  USER_PROFILE_UPDATED,
  LOGIN_FAILED,
  LOGIN_FAILED_RESET,
  USER_LOGIN,
  IMPERSONATION_FAILED,
  IMPERSONATION_FAILED_RESET,
  REPRESENT_CUSTOMER,
  EXPRESS_DELIVERY_STATUS_UPDATED, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  SET_CUTOFF_WARNING_VISIBILITY_STATUS, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  EXPRESS_DELIVERY__AND_DELIVERY_DATE_UPDATED, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  SHOW_CUTOFF_TIME_WARNING, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  SET_CHECKOUT_PAGE_LOADING, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  UPDATE_CALLOFF_ORDER_ADDRESS, 
  UPDATE_CALLOFF_ORDER_TYPE,
  UPDATE_CALLOFF_DELIVERY_DATE, 
  UPDATE_CALLOFF_ADDRESS_ID, 
  UPDATE_CUSTOM_SHIPPING_ADDRESS,
  UPDATE_DELIVERY_DATE,
  SET_CALL_OFF_SAVE_BTN_DISABLED,
} from './actions';

import { DOCUMENT_REORDER_RECEIVED } from '../../behavior/pages/document/actions'; //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery

import { NAVIGATED } from '../routing/actions'; //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
import { SAVE_CALL_OFF_ORDER_UPDATED, SAVE_CALL_OFF_ORDER_CLICKED } from '../../behavior/pages/document/actions'; //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
import { APP_STATE_UPDATED } from '../app/actions';
import { OFFLINE_MODE_CHANGED } from 'behavior/app';
import { createReducer } from 'utils/redux';

const initialState = {
  id: null,
  name: null,
  email: null,
  type: null,
  isAuthenticated: false,
  shopAccountType: null,
  isImpersonating: false,
  pricesInclTax: null,
  currencyId: null,
  customer: null,
  abilities: {},
  expiredAbilities: [],
  initialized: false,
  loginFailed: false,
  impersonationFailed: false,
  reqDeliveryDateUpdated: '', //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  isExpressDelivery: false, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  isCutoffWarningVisible: false, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  cutoffWarningVisible: false, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  isDateSelected: false, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  isChckoutPageLoading: false, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery

  //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  selectedCallOffExistingAddressId: null, 
  selectedCallOffOrderType: null, 
  selctedCallOffOrderDeliveryDate: null, 
  selectedAddressId: null, 
  customShippingAddress: [], 
  selectedDeliveryDate: null,
  isCallOffOrderSaved: false,
  saveCallOffBtnClicked: false,
  saveCallOffBtnDisabled: false,
};

export default createReducer(initialState, {
  [USER_AUTHENTICATED]: onUserAuthenticated,
  [OFFLINE_MODE_CHANGED]: onOfflineModeChanged,
  [USER_ABILITIES_LOADED]: onAbilitiesLoaded,
  [USER_PROFILE_UPDATED]: onUserProfileUpdated,
  [LOGIN_FAILED]: onLoginFailed,
  [LOGIN_FAILED_RESET]: resetLoginFailureStatus,
  [USER_LOGIN]: resetLoginFailureStatus,
  [IMPERSONATION_FAILED]: onImpersonationFailed,
  [IMPERSONATION_FAILED_RESET]: resetFailedImpersonationStatus,
  [REPRESENT_CUSTOMER]: resetFailedImpersonationStatus,
  [EXPRESS_DELIVERY__AND_DELIVERY_DATE_UPDATED]: onExpressDeliveryStatusUpdated, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  [EXPRESS_DELIVERY_STATUS_UPDATED]: onExpressDeliveryStateUpdated, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  [SET_CUTOFF_WARNING_VISIBILITY_STATUS]: onCutoffWarningVisiblityChanged, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  [SHOW_CUTOFF_TIME_WARNING]: onCutoffWarningVisible, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  [SET_CHECKOUT_PAGE_LOADING]: onCheckoutPageLoading,  //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  [DOCUMENT_REORDER_RECEIVED]: onReorderReceived, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  [NAVIGATED]: onCheckoutSubmitted, //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  [UPDATE_CALLOFF_ORDER_ADDRESS]: onCallOffOrderAddressChanged, //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  [UPDATE_CALLOFF_ORDER_TYPE]: onCallOffOrderTypeChanged, //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders

  [UPDATE_CALLOFF_DELIVERY_DATE]: onCallOffOrderDeliveryDateChanged, //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  [UPDATE_CALLOFF_ADDRESS_ID]: onAddressSelectionChanged, //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  [UPDATE_CUSTOM_SHIPPING_ADDRESS]: onCustomeCallOffAddressSelected, //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  [UPDATE_DELIVERY_DATE]: onDeliveryDateSelected, //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  [APP_STATE_UPDATED]: onAppStateUpdated, //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  [SAVE_CALL_OFF_ORDER_UPDATED]: onSaveCallOffOrderUpdated, //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  [SAVE_CALL_OFF_ORDER_CLICKED]: onSaveCallOffBtnclicked, //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  [SET_CALL_OFF_SAVE_BTN_DISABLED]: onSaveCallOffBtnDisabled, //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
});

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
function onSaveCallOffBtnDisabled(state, action) {
  return {
    ...state,
    saveCallOffBtnDisabled: action.payload.payload,
  };
}


//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
function onSaveCallOffBtnclicked(state, action) {
  return {
    ...state,
    saveCallOffBtnClicked: action.payload,
  };
}

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
function onCheckoutSubmitted(state) {
  if (!state.expressDelivery) {
    return {
      ...state,
      selectedDeliveryDate: '',
    };
  }
  return {
    ...state,
  };
}

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
function onReorderReceived(state) {
  return {
    ...state,
    isExpressDelivery: false,
  };
}


//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
function onCheckoutPageLoading(state, action) {
  return {
    ...state,
    isChckoutPageLoading: action.payload.status,
  };
}

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
function onCutoffWarningVisible(state, action) {
  return {
    ...state,
    cutoffWarningVisible: action.payload,
    isExpressDelivery: !action.payload,
  };
}

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
function onCutoffWarningVisiblityChanged(state, action) {
  return {
    ...state,
    isWithinCutoffTIme: action.payload,
  };
}

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
function onExpressDeliveryStateUpdated(state, action) {
  return {
    ...state,
    isExpressDelivery: action.payload.expressDeliverySelected
  };
}

//Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
function onExpressDeliveryStatusUpdated(state, action) {
  let formattedDate = action.payload;
  return {
    ...state,
    updatedExpressDeliveryStatus: formattedDate,
  };
}

function onUserAuthenticated(state, action) {
  return {
    ...action.payload,
    initialized: true,
    abilities: action.payload.abilities || state.abilities,
    expiredAbilities: action.payload.abilities ? [] : Object.keys(state.abilities),
  };
}

function onOfflineModeChanged(state, _action) {
  return {
    ...state,
    expiredAbilities: Object.keys(state.abilities),
  };
}

function onAbilitiesLoaded(state, action) {
  const loadedKeys = Object.keys(action.payload);
  const newExpiredAbilities = state.expiredAbilities.filter(k => !loadedKeys.includes(k));
  return {
    ...state,
    abilities: {
      ...state.abilities,
      ...action.payload,
    },
    expiredAbilities: newExpiredAbilities.length === state.expiredAbilities.length ? state.expiredAbilities : newExpiredAbilities,
  };
}

function onUserProfileUpdated(state = null, action) {
  return {
    ...state, ...action.payload,
  };
}

function onLoginFailed(state, _action) {
  return {
    ...state, loginFailed: true,
  };
}

function resetLoginFailureStatus(state, _action) {
  return state.loginFailed
    ? { ...state, loginFailed: false }
    : state;
}

function onImpersonationFailed(state, _action) {
  return {
    ...state, impersonationFailed: true,
  };
}

function resetFailedImpersonationStatus(state, _action) {
  return state.impersonationFailed
    ? { ...state, impersonationFailed: false }
    : state;
}

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
function onSaveCallOffOrderUpdated(state, action) {
  let status = action.payload.status.DocumentType && action.payload.status.DocumentType === "CallOffOrder" ? true : false;
  return {
    ...state,
    isCallOffOrderSaved: status,
  };
}

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
function onAppStateUpdated(state, _action) {
  return {
    ...state,
    selectedCallOffOrderType: null,
  };
}

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
function onCallOffOrderAddressChanged(state, _action) {
  let selectedShippingOption = _action.payload.payload;
  return {
    ...state,
    selectedShippingOption: _action.payload ? selectedShippingOption : "",
  };
}

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
function onCallOffOrderTypeChanged(state, _action) {
  return {
    ...state,
    selectedCallOffOrderType: _action.payload ? _action.payload.payload : "",
  };
}

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
function onCallOffOrderDeliveryDateChanged(state, _action) {
  return {
    ...state,
    slectedCallOffOrderDeliveryDate: _action.payload ? _action.payload.payload.deliveryDate : "",
  };
}

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
function onAddressSelectionChanged(state, _action) {
  return {
    ...state,
    selectedCallOffExistingAddressId: _action.payload ? _action.payload.payload : "",
  };
}

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
function onCustomeCallOffAddressSelected(state, _action) {
  return {
    ...state,
    customShippingAddress: _action.payload ? _action.payload.payload : [],
  };
}

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
function onDeliveryDateSelected(state, _action) {
  return {
    ...state,
    selectedDeliveryDate: _action.payload ? _action.payload.payload : "",
  };
}



