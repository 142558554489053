import type { Document } from './types';
import { QuoteStatus, DocumentType } from './constants';

export const isDocumentCancelled = (document: Document) =>
    document.status === QuoteStatus.Cancelled || document.status === 'CANCELLED';

export const isDocumentPromotableQuote = (document: Document, documentType: keyof typeof DocumentType) =>
    documentType === DocumentType.Quote && document.status === QuoteStatus.Orderable;

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
const fieldPerDocumentType = {
    [DocumentType.Order]: 'orders',
    [DocumentType.Quote]: 'quotes',
    [DocumentType.Invoice]: 'invoices',
    [DocumentType.ReturnOrder]: 'returnOrders',
    [DocumentType.CreditNote]: 'creditNotes',
    [DocumentType.ReturnReceipt]: 'returnReceipts',
    [DocumentType.Shipment]: 'shipments',
    [DocumentType.CallOffOrder]: 'callOffOrders',
} as const;

export function getDocumentField(documentType: DocumentType) {
    return fieldPerDocumentType[documentType];
}

//Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
const documentDetailsFieldMap = {
    [DocumentType.Order]: 'orderDetails',
    [DocumentType.Quote]: 'quoteDetails',
    [DocumentType.Invoice]: 'invoiceDetails',
    [DocumentType.ReturnOrder]: 'returnOrderDetails',
    [DocumentType.CreditNote]: 'creditNoteDetails',
    [DocumentType.ReturnReceipt]: 'returnReceiptDetails',
    [DocumentType.Shipment]: 'shipmentDetails',
    [DocumentType.CallOffOrder]: 'callOffOrderDetails',
} as const;

export function getDocumentDetailsField(documentType: keyof typeof documentDetailsFieldMap) {
    return documentDetailsFieldMap[documentType];
}
