import type { ProductCategoryCollection, Product } from './types';
import { SearchSuggestionsAction, SEARCH_PRODUCT_SUGGESTIONS, productsSearchSuggestionsCompleted } from './actions';
import { ofType } from 'redux-observable';
import { switchMap, map, pluck } from 'rxjs/operators';
import { searchSuggestionsQuery } from './queries';
import { routesBuilder } from 'routes';
import { retryWithToast } from 'behavior/errorHandling';
import { Epic } from 'behavior/types';

//Ticket 217512: [Konrad][Phase 4] 3.2 Display Search Results with Product Grouping
type ResponseProduct = {
    id: string;
    title: string | null;
    url: string | null;
    categoriesPaths: ProductCategoryCollection[];
    image: {
        small: string | null;
    } | null;
    stdProductGroup?: {
        id: string;
        title: string | null;
        url: string;
        defaultImage: {
            small: string | null;
        } | null;
    } | null;
};

type SearchSuggestionsResponse = {
    catalog: {
        products: {
            products: ResponseProduct[];
        };
    };
};

const epic: Epic<SearchSuggestionsAction> = (action$, _, { api, logger }) => {
    return action$.pipe(
        ofType(SEARCH_PRODUCT_SUGGESTIONS),
        pluck('payload'),
        switchMap(options =>
            api.graphApi<SearchSuggestionsResponse>(searchSuggestionsQuery, { options }).pipe(
                map(mapSuggestions),
                retryWithToast(action$, logger),
            ),
        ),
    );
};

export default epic;

//Ticket 217512: [Konrad][Phase 4] 3.2 Display Search Results with Product Grouping
export function mapSuggestions(data: SearchSuggestionsResponse) {
    const suggestedProducts: Product[] = [];

    for (const product of data.catalog.products.products) {
        const suggestedProduct: Product = {
            ...product,
            routeData: routesBuilder.forProduct(product.id),
            imageUrl: product.image && product.image.small,
            url: product.url + '?' + 'isSearch=true',
            productGroup: product.stdProductGroup ? {
                id: product.stdProductGroup.id,
                title: product.stdProductGroup.title,
                url: '/' + product.stdProductGroup.url + '?' + 'isSearch=true',
                //routeData: routesBuilder.forProductGroup(product.stdProductGroup.id),
                imageUrl: product.stdProductGroup.defaultImage && product.stdProductGroup.defaultImage.small,
            } : null,
        };

        delete suggestedProduct.image;

        suggestedProducts.push(suggestedProduct);
    }

    return productsSearchSuggestionsCompleted(suggestedProducts);
}
