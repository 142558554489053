import { attachmentQuery } from './queries';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { PageComponentNames } from '../componentNames';
import { initSystemPageContent } from '../system';
import { attachmentsReceived } from './actions';
import { loadSystemPageQuery } from '../system/queries';
import { generateKey } from 'utils/helpers';

//127167_Konrad_3.3_Customer_specific_document_download_FrontEnd
export default ({ params }, _state$, { api }) => {
  return api.graphApi(attachmentQuery).pipe(
    map(({ pages: { attachments: page }, attachments }) => {
      if (!page)
        return null;

      page.component = PageComponentNames.Attachments;

      return { page, action$: of(attachmentsReceived({ attachments })) };
    }),
    initSystemPageContent(),
  );
};
