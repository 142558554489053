import {
  BASKET_UPDATE,
  BASKET_UPDATED,
  BASKET_RECEIVED,
  BASKET_SUMMARY_RECEIVED,
  BASKET_ADD_PRODUCTS,
  BASKET_ARRIVED,
  BASKET_AGREEMENT_LINES_RECEIVED,
  VOLUME_PRICES_RECEIVED,
  volumePriceReceived
} from '../actions';
import { createReducer } from 'utils/redux';
import {
  VIEWER_CHANGED,
  LANGUAGE_CHANGED,
  BASKET_CHANGE_STARTED,
  BASKET_CHANGE_COMPLETED,
} from 'behavior/events';
import { USER_ANON_EXPIRED } from 'behavior/user';
import { Updaters } from '../constants';
import { NAVIGATED } from '../../routing';
import { generateKey } from 'utils/helpers';
import { initialState } from './constants';
import {
  AGREEMENT_APPLIED,
  AGREEMENT_CANCELED,
} from 'behavior/salesAgreements';

export default createReducer(initialState, {
  [BASKET_UPDATE]: onBasketUpdate,
  [BASKET_UPDATED]: onBasketUpdated,
  [BASKET_CHANGE_COMPLETED]: onBasketChangeCompleted,
  [BASKET_RECEIVED]: onBasketReceived,
  [BASKET_SUMMARY_RECEIVED]: onBasketSummaryReceived,
  [BASKET_CHANGE_STARTED]: onBasketChangeStarted,
  [BASKET_ADD_PRODUCTS]: onBasketUpdating,
  [VIEWER_CHANGED]: onBasketSummaryExpired,
  [USER_ANON_EXPIRED]: onBasketSummaryExpired,
  [LANGUAGE_CHANGED]: onBasketSummaryExpired,
  [NAVIGATED]: onNavigated,
  [BASKET_ARRIVED]: onBasketArrived,
  [AGREEMENT_APPLIED]: onAgreementApplied,
  [AGREEMENT_CANCELED]: onAgreementCanceled,
  [BASKET_AGREEMENT_LINES_RECEIVED]: onAgreementLinesReceived,
  [VOLUME_PRICES_RECEIVED]: onVolumePricesReceived
});

function onBasketUpdate(state) {
  return {
    ...state,
    updating: true,
  };
}

function onBasketUpdated(state, action) {
  const { updatedById, modifiedDate } = action.payload;

  return {
    ...state,
    updating: false,
    updated: {
      ...state.updated,
      updaterId: updatedById,
      date: modifiedDate,
    },
  };
}

function onBasketChangeCompleted(state, action) {
  const { updaterId: updatedById, date: updatedByDate } = state.updated;
  const { updatedLinesAmount } = action.payload;

  const updatedBySync = updatedById === Updaters.Sync;
  const updatedByBasket = updatedById === Updaters.Basket;
  const hasUpdatedLines = updatedLinesAmount > 0;

  const summaryExpired = updatedBySync
    ? updatedByDate !== state.modifiedDate
    : hasUpdatedLines || updatedByBasket;

  const modifiedDate = summaryExpired
    ? updatedByDate || Date.now()
    : state.modifiedDate;

  const loading = hasUpdatedLines && !updatedByBasket;

  return {
    ...state,
    modifiedDate,
    modelExpired: updatedBySync,
    summary: {
      ...state.summary,
      expired: summaryExpired,
      loading,
      loaded: !loading,
    },
    updated: {
      ...state.updated,
      linesAmount: updatedLinesAmount,
    },
  };
}

function onBasketReceived(state, action) {
  const {
    subTotal,
    totalPrice,
    totalPriceExcludingTax,
    prepayment,
    roundOff,
    modifiedDate,
    salesAgreementInfo,
    ...model
  } = action.payload.basket;

  if (!model.totals) {
    const totals = {
      sub: subTotal,
      price: totalPrice,
      priceExcludingTax: totalPriceExcludingTax,
      prepayment,
    };

    if (Object.values(totals).some(v => v != null)) {
      model.totals = totals;
      totals.roundOff = roundOff;
    }
  }

  if (model.productLines && model.productLines.list) {
    for (const line of model.productLines.list) {
      line.id = line.id || generateKey();

      if (line.subLines) {
        for (const subLine of line.subLines) {
          subLine.id = subLine.id || generateKey();
        }
      }
    }
  }

  model.page = action.payload.page;
  const result = {
    ...state,
    model,
    lastModifiedLineId: null,
    salesAgreementInfo: {
      ...salesAgreementInfo,
      loaded: true,
    },
  };

  delete result.syncBasket;
  delete result.modelExpired;
  const currentModifiedDate = state.modifiedDate;
  const newModifiedDate = addModifiedDate(result, modifiedDate);

  if (!isNaN(newModifiedDate) && currentModifiedDate !== newModifiedDate)
    result.summary = {
      ...state.summary,
      isAvailable: model.isAvailable,
      expired: true,
    };
  else if (state.summary.isAvailable !== model.isAvailable)
    result.summary = {
      ...state.summary,
      isAvailable: model.isAvailable,
    };

  result.isQuickOrderMode = false;

  return result;
}

function onBasketSummaryReceived(state, action) {
  const { basket } = action.payload;

  if (!basket)
    return { ...state, summary: null };

  const {
    modifiedDate,
    salesAgreementInfo,
    ...summary
  } = basket;
  const result = {
    ...state,
    summary,
    salesAgreementInfo: {
      ...salesAgreementInfo,
      loaded: true,
    },
  };

  summary.loaded = true;
  summary.loading = false;
  addModifiedDate(result, modifiedDate);

  return result;
}

function onBasketSummaryExpired(state) {
  return {
    ...state,
    summary: {
      ...state.summary,
      expired: true,
    },
    updated: {
      ...state.updated,
      linesAmount: 0,
    },
  };
}

function onBasketChangeStarted(state) {
  return {
    ...state,
    updated: {
      updaterId: null,
      date: null,
      linesAmount: 0,
    },
    summary: {
      ...state.summary,
      loaded: false,
      loading: true,
    },
  };
}

function onBasketUpdating(state, action) {
  return {
    ...state,
    updatingBy: action.payload.updatedById,
  };
}

function onNavigated(state) {
  if (state.modelExpired || state.syncBasket || state.updated.updaterId || (state.summary?.expired && state.summary.loading)) {
    const result = {
      ...state,
      updated: {
        linesAmount: 0,
        updaterId: null,
        date: null,
      },
    };
    delete result.modelExpired;
    delete result.syncBasket;
    return result;
  }
  return state;
}

function onBasketArrived(state, action) {
  return {
    ...state,
    syncBasket: action.payload,
  };
}

function onAgreementApplied(state, action) {
  return {
    ...state,
    salesAgreementInfo: {
      ...state.salesAgreementInfo,
      id: action.payload.salesAgreementId,
      isAppliedToLines: false,
    },
  };
}

function onAgreementCanceled(state) {
  return {
    ...state,
    salesAgreementInfo: {
      ...state.salesAgreementInfo,
      id: undefined,
      isAppliedToLines: false,
    },
  };
}

function addModifiedDate(result, modifiedDate) {
  if (modifiedDate)
    return result.modifiedDate = typeof (modifiedDate) === 'string' ? +new Date(modifiedDate) : modifiedDate;
}

function onAgreementLinesReceived(state, action) {
  const { agreementLines, basketLineId } = action.payload;
  const productLinesList = state.model.productLines?.list || [];

  if (agreementLines.length === 0 || productLinesList.length === 0)
    return state;

  const [line, lineIndex] = getBasketLine(productLinesList, basketLineId);
  if (!line || !line.availableSalesAgreementLines)
    return state;

  const salesAgreementLines = [];
  const productUomId = line.uom.id.toUpperCase();

  line.availableSalesAgreementLines.forEach(({ id: availableAgreementLineId }) => {
    const availableAgreementLine = agreementLines.find(line => {
      return line.id === availableAgreementLineId && (line.uom ? line.uom.id.toUpperCase() === productUomId : true);
    });

    if (availableAgreementLine)
      salesAgreementLines.push(availableAgreementLine);
  });

  if (salesAgreementLines.length === 0)
    return state;

  const newLine = {
    ...line,
    salesAgreementLines,
  };

  return updateState(state, newLine, lineIndex);
}

function getBasketLine(productLinesList, basketLineId) {
  for (let i = 0; i < productLinesList.length; i++) {
    if (productLinesList[i].id === basketLineId)
      return [productLinesList[i], i];

    if (!productLinesList[i].subLines)
      continue;

    const subLine = productLinesList[i].subLines.find(subLine => subLine.id === basketLineId);
    if (subLine)
      return [subLine, i];
  }
  return [];
}

function updateState(state, newLine, lineIndex) {
  const productLinesList = state.model.productLines.list;

  const newState = {
    ...state,
    model: {
      ...state.model,
      productLines: {
        ...state.model.productLines,
        list: [...productLinesList],
      },
    },
  };

  let newProductLine;
  if (productLinesList[lineIndex].subLines) {
    newProductLine = {
      ...state.model.productLines.list[lineIndex],
      subLines: [...productLinesList[lineIndex].subLines],
    };
    const subLineIndex = productLinesList[lineIndex].subLines.findIndex(subLine => subLine.id === newLine.id);
    newProductLine.subLines[subLineIndex] = newLine;
  }

  newState.model.productLines.list[lineIndex] = newProductLine ?? newLine;

  return newState;
}

//240779_P5_3.6_Display_of_Relay_Prices
function onVolumePricesReceived(state, action) {
  return {
    ...state,
    volumePrices: action.payload,
  };
}