import { of, merge, throwError } from 'rxjs';
import { combineEpics } from 'utils/rxjs';
import { ofType } from 'redux-observable';
import {
  switchMap, mergeMap,
  pluck, catchError, startWith
} from 'rxjs/operators';
import { NAVIGATED } from 'behavior/routing';
import {
  APP_STATE_UPDATE, APP_INIT, notifyAppStateUpdated,
  changeOfflineMode,
  SHOW_HIDE_LIST_PRICE,
  updatedListPriceStatus,
} from './actions';
import { appStateQuery, showListPriceMutation } from './queries';
import { catchApiErrorWithToast } from 'behavior/errorHandling';
import { setLoadingIndicator, unsetLoadingIndicator } from 'behavior/loadingIndicator';
import { navigateTo } from 'behavior/events';

const setLoading = setLoadingIndicator();

const appEpic = (action$, state$, { api }) => action$.pipe(
  ofType(NAVIGATED, APP_INIT, APP_STATE_UPDATE),
  switchMap(_ => api.graphApi(appStateQuery).pipe(
    mergeMap(({ appState }) => {
      const appStateUpdated = notifyAppStateUpdated(appState);
      if (state$.value.app.offlineMode === appState.offlineMode)
        return of(appStateUpdated);

      return of(appStateUpdated, changeOfflineMode(appState));
    }),
    catchError(e => merge(of(notifyAppStateUpdated({ error: true })), throwError(e))),
  )),
);

//[127166] [Konrad] 3.2 Display customer specific prices
function setListPriceStatusEpic(_action$, state$, { api }) {
  return _action$.pipe(
    ofType(SHOW_HIDE_LIST_PRICE),
    switchMap(({ payload }) => api.graphApi(showListPriceMutation, { input: payload }).pipe(
      pluck('profile', 'showListPrice', 'showHideListPriceResult'),
      mergeMap(showHideListPriceResult => {
        const pathname = state$.value.routing.location.pathname;
        const search = state$.value.routing.location.search;
        const to = state$.value.routing.routeData;
        return of(
          updatedListPriceStatus(showHideListPriceResult),
          navigateTo(to, pathname + search),
        )

      }),
      catchApiErrorWithToast(),
      startWith(setLoading),
    )),
  );
}

//[127166] [Konrad] 3.2 Display customer specific prices
export default combineEpics(
  appEpic,
  setListPriceStatusEpic
);

