import PropTypes from 'prop-types';
import { AbilityState } from './constants';

export const abilityPropType = PropTypes.oneOf([
  AbilityState.Unauthorized,
  AbilityState.Available,
  AbilityState.NotAvailable,
  AbilityState.TemporaryNotAvailable,
]);

export const abilitiesPropType = PropTypes.arrayOf(abilityPropType);

export {
  login,
  register,
  logout,
  representCustomer,
  stopRepresentingCustomer,
  loadUserAbilities,
  USER_LOGOUT,
  createProspect,
  USER_PROFILE_UPDATED,
  userProfileUpdated,
  resetLoginFailed,
  resetImpersonationFailed,
  reloadUser,

  //Ticket 176742: [Konrad][Phase 2] 3.7. Call off orders
  //Ticket 176744: [Konrad] [Phase 2] 3.8. Express delivery
  UPDATE_CALLOFF_ORDER_ADDRESS, 
  updateCallOffOrderAddressRequested, 
  updateCallOffOrderTypeRequested, 
  updateCallOffOrderDeliveryDateRequested, 
  callOffOrderAddressSelected,
  onCustomcallOffOrderAddressSelected, 
  onDeliveryDateSelected,
  notifyExpressDeliverySelected, 
  chengeCutoffWarningVisibility,
  saveCallOffBtnDiabled,
} from './actions';

export { USER_ANON_EXPIRED } from './broadcastActions';

export { CustomerTypes, UserTypes, ShopAccountTypes, PriceOption } from './constants';
